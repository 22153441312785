<script setup lang="ts">
import { CdekToaster } from '@cdek-ui-kit/vue';
import { onMounted, ref } from 'vue';

const props = defineProps<{
  title?: string;
  text?: string;
  html?: string;
}>();
const isShow = ref(false);

const tooltipRef = ref();
onMounted(() => {
  if (!props.html) {
    return;
  }
  const el = tooltipRef.value.querySelector('.toast__text');
  if (!el) {
    return;
  }
  el.innerHTML = props.html as string;
});
</script>

<template>
  <div ref="tooltipRef" class="tooltip">
    <div class="tooltip__icon" @mouseover="isShow = true" @mouseleave="isShow = false">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 15V15.01M10 11.5C9.98159 11.1754 10.0692 10.8535 10.2495 10.583C10.4299 10.3125 10.6933 10.1078 11 9.99998C11.3759 9.85624 11.7132 9.62722 11.9856 9.33094C12.2579 9.03465 12.4577 8.6792 12.5693 8.29257C12.6809 7.90594 12.7013 7.49868 12.6287 7.10285C12.5562 6.70702 12.3928 6.33343 12.1513 6.0115C11.9099 5.68956 11.597 5.42807 11.2373 5.2476C10.8776 5.06713 10.4809 4.97262 10.0785 4.97151C9.67611 4.97039 9.27892 5.0627 8.91824 5.24117C8.55756 5.41964 8.24323 5.67939 8 5.99998M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
          stroke="#1AB248"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <Transition>
      <div v-show="isShow" class="tooltip__alert">
        <CdekToaster :title="(title as string)" :text="text" />
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.tooltip {
  position: relative;
  &__icon {
    display: flex;
  }
  &__alert {
    position: absolute;
    z-index: 1;
    width: 30rem;
  }
  .toast {
    max-width: 30rem;
    .toast__info {
      max-width: 30rem;
    }
    .toast__close {
      display: none;
    }
    .toast__content {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
}
</style>
