<script setup lang="ts">
import Description from './Description.vue';
import { computed } from 'vue';

const props = defineProps<{
  price: number | string;
}>();

const description = computed(() => {
  return [
    {
      title: 'Итого',
      value: `${props.price} ₽`,
    },
  ];
});
</script>

<template>
  <Description :items="description" class="totalPrice" />
</template>

<style lang="scss" scoped>
.item__title {
  color: red !important;
}
</style>
