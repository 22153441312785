<script setup lang="ts">
import { CdekHeadline, CdekSpinner, CdekLink } from '@cdek-ui-kit/vue';
import ElementBasis from '../../other/ElementBasis.vue';
import AdditionalServiceDefaultComponent from '@/components/other/additionalServices/AdditionalServicesDefault.vue';
import { computed, reactive } from 'vue';
import type {
  AvailableAdditionServicesInterface,
  CalculatedAdditionalServicesInterface,
} from '@/interfaces/GetServiceResponceInterface';
import Tooltip from '../../other/Tooltip.vue';
import { useI18n } from 'vue-i18n';
import useInjectContainer from '@/composables/useInjectContainer';
import SelectTariffService from '@/services/SelectTariff/SelectTariffService';
import FieldsDTO from '@/services/FieldsDTO';

const { t } = useI18n();

const props = defineProps<{
  availableServices: AvailableAdditionServicesInterface[];
  calculatedServices: CalculatedAdditionalServicesInterface[];
  showSpinner: boolean;
}>();
defineEmits<{
  (e: 'selectService'): void;
}>();

const container = useInjectContainer();
const selectTariffService = container.get(SelectTariffService);
const fields = container.get(FieldsDTO);

fields.other = {
  selectedAdditionalServices: computed(() => {
    return ((props.availableServices as []) ?? []).filter(
      (el: { isAdded?: boolean }) => el.isAdded,
    );
  }),
};

const additionalServicePrice = (service: AvailableAdditionServicesInterface) => {
  return props.calculatedServices.find((item) => item.alias === service.alias)?.paymentDetail
    .netCost;
};

const additionalServicesList = reactive({});
const additionalServicesCount = reactive({});
const additionalServices = computed(() => {
  const result = [];
  Object.keys(additionalServicesList).forEach((service) => {
    if (!additionalServicesList[service]) {
      return;
    }
    result.push({
      alias: service,
      countOfService: additionalServicesCount[service],
    });
  });
  return result;
});
</script>

<template>
  <div class="services">
    <ElementBasis v-if="availableServices?.length || showSpinner">
      <div class="services__title">
        <CdekHeadline size="6">Дополнительные услуги</CdekHeadline>
        <div v-if="showSpinner" class="services__spinner">
          <CdekSpinner />
        </div>
      </div>
      <div class="services__list">
        <AdditionalServiceDefaultComponent
          v-for="(service, index) in availableServices"
          :key="index"
          v-model="additionalServicesList[service.alias]"
          v-model:countOfService="additionalServicesCount[service.alias]"
          :price="(additionalServicePrice(service) as number)"
          :title="service.title"
          :parameters="service.parameterCodes"
          @select-service="selectTariffService.selectAdditionService(additionalServices)"
        >
          <template v-if="service.alias === 'insurance'" #after>
            <div class="link-after">
              <CdekLink
                ><a
                  href="https://www.cdek.ru/storage/source/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D0%B8/%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F%20%D0%A1%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
                  target="_blank"
                  >Условия страхования грузов</a
                ></CdekLink
              >
            </div>
          </template>
          <template v-if="service.alias === 'insurance'" #before-description>
            <Tooltip :text="t('selectTariff.additionalServices.insuranceTooltip')" />
          </template>
        </AdditionalServiceDefaultComponent>
      </div>
    </ElementBasis>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.services {
  margin: 1rem 0;
  &__title {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
  }
  &__list {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
.link-after {
  display: flex;
  @include media-xs {
    flex-basis: 100%;
    justify-content: flex-end;
  }
}
</style>
