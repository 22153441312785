<script setup lang="ts">
import TariffsList from './TariffsList.vue';
import AdditionalServices from './AdditionalServices.vue';
import { ref } from 'vue';
import Tariff from './Tariff.vue';
import TariffsService from '@/services/TariffsService';
import useInjectContainer from '@/composables/useInjectContainer';
import WidgetProps from '@/services/WidgetProps';
import FieldsDTO from '@/services/FieldsDTO';
import CalculateService from '@/services/Calculate/CalculateService';
import SelectTariffService from '@/services/SelectTariff/SelectTariffService';
import type WithAdditionServices from '@/decorators/SelectTariff/WithAdditionServices';

defineProps<{
  payload: any;
}>();
const container = useInjectContainer();
const widgetProps = container.get(WidgetProps);
const fields = container.get(FieldsDTO);
const calculateService = container.get(CalculateService);
const selectTariffService = container.get(SelectTariffService) as unknown as WithAdditionServices;

const tariffsService = container.get(TariffsService);
tariffsService.tariffs = calculateService.tariffs?.serviceList ?? [];

const showSpinnerAddServices = ref(false);
const deliveryPeriod = (min: string | number, max: string | number) => {
  if (min === max) {
    return min;
  }
  return `${min} - ${max}`;
};

fields.tariff = null;
fields.modeId = null;
</script>

<template>
  <TariffsList @show-spinner-add-services="(value) => (showSpinnerAddServices = value)">
    <div v-for="(tariff, index) in tariffsService.tariffs" :key="index" class="tariff__list">
      <div class="tariff__radio">
        <Tariff
          v-for="mode in tariff.modeDetails"
          :key="mode.tariffEc4Id + Math.random()"
          :tariff-ec4-id="mode.tariffEc4Id"
          :service-name="tariff.serviceName"
          :mode-name="mode.modeName"
          :price="mode.price"
          :delivery-mode="mode.deliveryPoints"
          :delivery-period="deliveryPeriod(mode.calendarPeriodMin, mode.calendarPeriodMax)"
          :currency-symbol="widgetProps.currencySymbol"
          @select-service-mode-id="(id: number) => selectTariffService.selectService(id, tariff)"
        />
      </div>
    </div>
  </TariffsList>
  <AdditionalServices
    :key="fields.modeId"
    :available-services="selectTariffService.filteredAdditionalServices ?? []"
    :calculated-services="fields.mode?.calculatedAdditionalServices ?? []"
    :show-spinner="showSpinnerAddServices"
  />
</template>
