import AbstractConfigurator from '@/services/AbstractConfigurator';
import WithAdditionServices from '@/decorators/SelectTariff/WithAdditionServices';

export default class SelectTariffConfigurator extends AbstractConfigurator {
  public decorators = [
    {
      name: 'withAdditionServices',
      class: WithAdditionServices,
    },
  ];
}
