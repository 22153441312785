<script setup lang="ts">
import RadioButton from '@/components/other/RadioButton.vue';
import ElementBasis from '@/components/other/ElementBasis.vue';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import { inject, toRef } from 'vue';

const container = useInjectContainer();
const fields = container.get(FieldsDTO);

const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.client.type = null;

const clientTypeRef = toRef(fields.client, 'type');
const typeValidator = validatorsBuilder.build(clientTypeRef);
</script>

<template>
  <div class="legal-wrapper">
    <ElementBasis>
      <div class="legal">
        <RadioButton v-model="fields.client.type" value="ООО/АО">
          <template #title>ООО/АО</template>
        </RadioButton>
        <RadioButton v-model="fields.client.type" value="ИП">
          <template #title>ИП</template>
        </RadioButton>
        <RadioButton v-model="fields.client.type" value="Самозанятый">
          <template #title>Самозанятый</template>
        </RadioButton>
      </div>
    </ElementBasis>
    <div v-if="typeof typeValidator.validated.value === 'string'" class="legal-error">
      {{ typeValidator.validated.value }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.legal-wrapper {
  margin: 1rem 0;
}
.legal {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  .green-radio {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: fit-content;
  }
  &-error {
    color: $Error;
    @include caption-1;
    margin-left: 1rem;
  }
}
</style>
