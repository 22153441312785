const langs = {
  rus: {
    calculate: {
      title: 'Рассчитать стоимость отправления',
      city: 'Город',
      from: 'Откуда',
      to: 'Куда',
      notFoundCity: 'Ничего не нашлось',
      tariffsNotFound: {
        title: 'Подходящие тарифы не найдены',
        description: 'Попробуйте ввести другие параметры',
      },
      packages: {
        weight: 'Вес, кг',
        length: 'Длина, см',
        width: 'Ширина, см',
        height: 'Высота, см',
        cm: 'см',
        kg: 'кг',
        notLessThan: 'Не менее',
        notMoreThan: 'Не более',

        withSelect: {
          length: 'Длина',
          width: 'Ширина',
          height: 'Высота',
          weight: 'Вес',
          approximateDimensions: 'Примерно',
          exactDimensions: 'Точные',
          upTo: 'до {0}',
          maxField: 'Максимальное значение: {max}',
          minField: 'Минимальное значение: {min}',
          cm: 'см',
          kg: 'кг',
          empty: 'Не выбрано',
          requiredField: 'Обязательное поле',
          confirm: 'Подтвердить',
          select: 'Выбрать',
        },
      },
      description:
        'После заполнения формы отправки наши сотрудники проверят фактический и объемный вес Вашего груза, подлежащего доставке. Оплата будет произведена за большее из двух значений.',
      buttonSubmit: 'Рассчитать',
    },
    selectTariff: {
      title: 'Выберите тариф',
      linkBack: 'Изменить параметры',
      changeDirection: 'Изменить направление',
      tariffsList: {
        entrypoints: {
          door: 'От двери',
          point: 'От склада',
          postamat: 'От постамата',
          terminal: 'От терминала',
        },
        endpoints: {
          door: 'До двери',
          point: 'До склада',
          postamat: 'До постамата',
          terminal: 'До терминала',
        },
      },
      days: 'дн.',
      additionalServices: {
        parameters: {
          count: 'штук',
          days: 'дней',
          meters: 'метров',
          floor: 'этажей',
          declaredCost: 'RUB',
        },
        insuranceTooltip: 'Введите ценность груза',
      },
      buttonSubmit: 'Выбрать',
      makeRequest: 'Зарегистрироваться',
      daysPlural: '0\xa0дней | {n}\xa0день | {n}\xa0дня | {n}\xa0дней',
      createOrder: 'Создать заказ',
    },
    selectTariffDocuments: {
      tariffsList: {
        entrypoints: {
          door: 'От двери',
          point: 'От пункта выдачи',
          postamat: 'От постамата',
          terminal: 'От терминала',
        },
        endpoints: {
          door: 'До двери',
          point: 'До пункта выдачи',
          postamat: 'До постамата',
          terminal: 'До терминала',
        },
      },
    },
    sendRequest: {
      title: 'Контактные данные',
      description:
        'Менеджер свяжется с вами, чтобы подробнее рассказать о тарифах и условиях доставки',
      email: 'Email',
      name: 'Имя',
      phone: 'Телефон (+7)',
      iamAgreeWith: 'Я соглашаюсь с',
      iamAgreeWithLink: 'условиями обработки персональных данных',
      senderIs: 'Отправитель',
      senderIsPerson: 'Физическое лицо',
      senderIsBusiness: 'Юридическое лицо',
      buttonSubmit: 'Отправить',
    },
    final: {
      requestSuccess: 'Заявка отправлена!',
      description:
        'Менеджер свяжется с вами, чтобы подробнее рассказать о тарифах и условиях доставки',
      recalculate: 'Новый расчет',
    },
    inputError: 'Некорректное значение',
    tariffs: {
      '25052a3e-ee40-45b9-985b-259cf49f7947': {
        name: 'Parcel Express',
      },
      '5f025d2c-ffab-4322-88e5-4af046094b2d': {
        name: 'Parcel Standart',
      },
      'e23ae16b-1a58-4724-8171-b85586ee523b': {
        name: 'E-com Economy',
      },
      'e5b330a7-389f-49ff-9798-516577f0a24a': {
        name: 'Parcel Economy',
      },
      'c0a2bd9b-3e9c-491f-b745-bd9f2782bbc9': {
        name: 'Documents Express',
      },
      'b4a9658c-3212-494b-bb18-42bc3d14fd69': {
        name: 'Documents Standart',
      },
    },
  },
  eng: {
    calculate: {
      title: 'Calculate shipping cost',
      city: 'City',
      from: 'From',
      to: 'To',
      notFoundCity: 'Nothing found',
      tariffsNotFound: {
        title: 'No suitable tariffs found',
        description: 'Try entering different parameters',
      },
      packages: {
        weight: 'Weight (kg)',
        length: 'Length (cm)',
        width: 'Width (cm)',
        height: 'Height (cm)',
        notLessThan: 'Not less than',
        notMoreThan: 'Not more than',
        cm: 'cm',
        kg: 'kg',
      },
      description:
        'Once you have filled out the form, our employees will check the actual weight and volume weight of your cargo that is subject to delivery. You will be charged for whichever one is larger.',
      buttonSubmit: 'Calculate',
    },
    selectTariff: {
      title: 'Select tariff',
      linkBack: 'Change parameters',
      tariffsList: {
        entrypoints: {
          door: 'From door',
          point: 'From warehouse',
          postamat: 'From parcel locker',
        },
        endpoints: {
          door: 'To door',
          point: 'To warehouse',
          postamat: 'To parcel locker',
        },
      },
      days: 'days',
      additionalServices: {
        parameters: {
          count: 'items',
          days: 'days',
          meters: 'meters',
          floor: 'floors',
        },
      },
      insuranceTooltip: 'Enter the value of the cargo',
      buttonSubmit: 'Select',
    },
    sendRequest: {
      title: 'Contact details',
      description:
        'A manager will be in contact to tell you more about the tariffs and delivery terms and conditions',
      email: 'Email',
      name: 'First name',
      phone: 'Tel. no.',
      iamAgreeWith: 'I agree to',
      iamAgreeWithLink: 'the terms and conditions for the processing of personal data',
      senderIs: 'Sender',
      senderIsPerson: 'Individual',
      senderIsBusiness: 'Legal entity',
      buttonSubmit: 'Send',
    },
    final: {
      requestSuccess: 'Request sent!',
      description:
        'A manager will be in contact to tell you more about the tariffs and delivery terms and conditions',
      recalculate: 'New calculation',
    },
    inputError: 'Invalid value',
  },
  tur: {
    calculate: {
      title: 'Gönderme ücretini hesapla',
      from: 'Nereden',
      to: 'Nereye',
      notFoundCity: 'Hiçbir şey bulunamadı',
      tariffsNotFound: {
        title: 'Uygun tarife bulunamadı',
        description: 'Diğer parametreleri girmeyi deneyin',
      },
      buttonSubmit: 'Hesaplamak',
      packages: {
        weight: 'Ağırlık, kg',
        length: 'Uzunluk, cm',
        width: 'Genişlik, cm',
        height: 'Yükseklik, cm',
      },
      description:
        'Formu doldurduktan sonra çalışanlarımız teslim edilecek kargonuzun gerçek ve hacimsel ağırlığını kontrol edeceklerdir. İki değerden yüksek olanı için ödeme yapılacaktır.',
    },
    selectTariff: {
      title: 'Tarife seçin',
      linkBack: 'Parametreyi değiştirmek',
      tariffsList: {
        entrypoints: { door: 'Kapıdan', point: 'Depodan', postamat: 'Postomattan' },
        endpoints: { door: 'Kapıya', point: 'Depoya', postamat: 'Potomata' },
      },
      days: 'gün',
      additionalServices: {
        parameters: { count: 'adet', days: 'gün', meters: 'metre', floor: 'kat' },
      },
      insuranceTooltip: 'Kargonun değerini girin',
      buttonSubmit: 'Seçmek',
    },
    sendRequest: {
      description:
        'Yönetici, tarifeler \nve teslimat koşulları\n hakkında daha fazla\n bilgi vermek için sizinle\n iletişime geçecektir.',
      email: 'E-posta',
      name: 'İsim',
      phone: 'Telefon numarası (+90)',
      iamAgreeWith: 'Kabul ediyorum',
      iamAgreeWithLink: 'kişisel bilgileri işleme şartlarını',
      senderIs: 'Gönderici',
      senderIsPerson: 'Gerçek kişi',
      senderIsBusiness: 'Tüzel kişi',
      buttonSubmit: 'Göndermek',
    },
    final: {
      requestSuccess: 'Başvuru gönderildi!',
      description:
        'Yönetici, tarifeler ve teslimat koşulları hakkında daha fazla bilgi vermek için sizinle iletişime geçecektir.',
      recalculate: 'Yeni hesaplama',
    },
    inputError: 'Geçersiz değer',
  },
};
export default langs;
