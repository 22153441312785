import { addAlpha, hexToHsl } from './helpers';

function useCustomColor(color: string) {
  if (!/^#[0-9A-Fa-f]{6}$/i.test(color)) {
    throw new Error('Цвет должен быть в формате #RRGGBB.');
  }

  // Преобразуем основной цвет в HSL
  const baseHsl = hexToHsl(color);

  // Вычисляем все переменные
  const variables = {
    '--Primary': color,
    '--Primary_Calm': `hsl(${baseHsl.h}, ${baseHsl.s - 60}%, ${baseHsl.l + 20}%)`,
    '--Primary_Active': `hsl(${baseHsl.h}, ${baseHsl.s}%, ${baseHsl.l - 10}%)`,
    '--Primary_70': addAlpha(color, 0.7),
    '--Primary_50': addAlpha(color, 0.5),
    '--Primary_40': addAlpha(color, 0.4),
    '--Primary_30': addAlpha(color, 0.3),
    '--Primary_20': addAlpha(color, 0.2),
    '--Primary_10': addAlpha(color, 0.1),
    '--Primary_5': addAlpha(color, 0.05),
    '--Primary_Button': color,
    '--Primary_Button_Hover': `hsl(${baseHsl.h}, ${baseHsl.s + 20}%, ${baseHsl.l + 20}%)`,
    '--Secondary_Button': addAlpha(color, 0.1),
  };

  // Устанавливаем CSS переменные
  const styles = Object.entries(variables)
    .map(([key, value]) => `${key}: ${value};`)
    .join(' ');

  document.body.setAttribute('style', styles);
}

export default useCustomColor;
