export default class DeliveryModesDTO {
  protected _modes = [
    {
      code: 1,
      entrypoint: 'door',
      endpoint: 'door',
    },
    {
      code: 2,
      entrypoint: 'door',
      endpoint: 'point',
    },
    {
      code: 3,
      entrypoint: 'point',
      endpoint: 'door',
    },
    {
      code: 4,
      entrypoint: 'point',
      endpoint: 'point',
    },
    {
      code: 5,
      entrypoint: 'terminal',
      endpoint: 'terminal',
    },
    {
      code: 6,
      entrypoint: 'door',
      endpoint: 'postamat',
    },
    {
      code: 7,
      entrypoint: 'point',
      endpoint: 'postamat',
    },
    {
      code: 8,
      entrypoint: 'postamat',
      endpoint: 'door',
    },
    {
      code: 9,
      entrypoint: 'postamat',
      endpoint: 'point',
    },
    {
      code: 10,
      entrypoint: 'postamat',
      endpoint: 'postamat',
    },
  ];

  public getDeliveryModes = (code: string | number) => {
    return this._modes.find((mode) => mode.code === Number(code));
  };
}
