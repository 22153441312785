<script setup lang="ts">
import { CdekHeadline } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
  <CdekHeadline size="3">{{ t('sendRequest.title') }}</CdekHeadline>
</template>

<style lang="scss">
.headline-3 {
  margin-bottom: 1rem;
}
</style>
