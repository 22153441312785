import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#627791",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-opacity": ".5",
      "stroke-width": "1.75",
      d: "M10 6.667h.008M9.167 10H10v3.333h.833M17.5 10a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0"
    }, null, -1)
  ])))
}
export default { render: render }