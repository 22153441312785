<script lang="ts" setup>
import { computed } from 'vue';
import ChevronUpIcon from '../icons/chevron-up.svg?component';

const props = defineProps<{
  open: boolean;
  value: string;
  label?: string;
  validRes?: boolean | string;
  hideErrorMessage?: boolean;
}>();

defineEmits<{
  (e: 'toggle'): void;
}>();
const isError = computed(() => typeof props.validRes === 'string');
</script>

<template>
  <div class="dropdown-trigger" @click="$emit('toggle')">
    <div
      class="dropdown-trigger__control"
      :class="{
        'dropdown-trigger__control_error': isError,
        'dropdown-trigger__control_open': open,
      }"
    >
      <label
        v-if="label"
        class="dropdown-trigger__label"
        :class="{
          'dropdown-trigger__label_filled': Boolean(value),
          'dropdown-trigger__label_error': isError,
          'dropdown-trigger__label_open': open,
        }"
      >
        {{ label }}
      </label>

      <div
        class="dropdown-trigger__value"
        :class="{
          'dropdown-trigger__value_error': isError,
          'dropdown-trigger__value_no-label': !label,
          'dropdown-trigger__value_open': open,
        }"
      >
        {{ value }}
      </div>

      <chevron-up-icon
        class="dropdown-trigger__arrow"
        :class="{
          'dropdown-trigger__arrow_red': isError,
          'dropdown-trigger__arrow_open': open,
        }"
      />
    </div>
    <div class="dropdown-trigger__tip">
      <template v-if="isError">
        <span v-show="!hideErrorMessage" class="error">{{ validRes }}</span>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
@mixin slotted-svg-color($color) {
  :slotted(svg) {
    path {
      stroke: $color;
    }
  }
}

.dropdown-trigger {
  $padding-left: 16px;

  position: relative;

  &__control {
    $this: &;
    $outline-width: 2px;

    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    border: none;
    outline: solid $outline-width transparent;
    padding-inline: calc(#{$padding-left} - #{$outline-width});
    padding-block: 6px;
    box-sizing: border-box;
    background: $Surface_Neutral;
    box-shadow: inset 0 1px 2px rgba(0, 33, 52, 0.05);
    border-radius: 8px;
    transition: background-color 0.3s ease, outline-color 0.3s ease;

    &_error {
      background: $Error_5;
    }

    @include media-hover {
      background: $Primary_10;
    }

    &#{$this}_error:not(&#{$this}_open) {
      @include media-hover {
        background: $Error_10;
      }
    }

    &#{$this}_open {
      background: $Primary_Active;
    }
  }

  &__value {
    @include body-1;

    background: unset;
    border: unset;
    outline: unset;
    flex-grow: 1;
    color: $Bottom;
    caret-color: $Primary;
    align-self: flex-end;
    transition: color 0.3s ease;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;

    &_error {
      caret-color: $Error;
    }

    &[disabled] {
      color: $Bottom_66;
    }

    &_open {
      color: $Peak;
    }

    &_no-label {
      align-self: center;
    }
  }

  &__label {
    $this: &;

    @include body-1;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $Bottom_60;
    transition: all 0.3s ease;

    &_filled {
      @include caption-1;

      top: 8px;
      transform: translateY(0);
    }

    .dropdown-trigger__control:not(:focus-within) &_error#{$this}_filled:not(&#{$this}_open) {
      color: $Error;
    }

    &_open:not(&_small) {
      color: $Peak;
    }
  }

  &__tip {
    @include caption-1;

    padding-left: $padding-left;
    margin-top: 4px;
    color: $Bottom_66;
    min-height: 16px;

    :slotted(.tertiary) {
      color: $Tertiary;
    }

    :slotted(.attention) {
      color: $Attention;
    }

    .error,
    :slotted(.error) {
      color: $Error;
    }

    :slotted(.success) {
      color: $Success;
    }

    svg,
    :slotted(svg) {
      vertical-align: text-bottom;
      margin-right: 4px;
    }
  }

  &__arrow {
    stroke: $Primary;
    margin-right: 6px;
    transform: rotate(180deg);
    transition: transform 0.2s ease;

    &_open {
      stroke: $Peak;
      transform: rotate(0deg);
    }

    &_red:not(&_open) {
      stroke: $Error;
    }
  }
}
</style>
