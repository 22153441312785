<script setup lang="ts">
import { CdekHeadline } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

defineProps<{
  endpoints: string[];
}>();
</script>

<template>
  <div class="options">
    <template v-for="(endpoint, index) in endpoints" :key="index">
      <div class="option-card">
        <CdekHeadline size="7">{{
          t(`selectTariff.tariffsList.endpoints.${endpoint}`)
        }}</CdekHeadline>
        <slot :endpoint="endpoint"></slot>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
@import '@cdek-ui-kit/vue/scss';
.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
}
.option-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: $Tertiary_5;
  border-radius: 10px;
}
</style>
