export type ComponentType = {
  key: string;
  component: ComponentType | any;
};
export type ComponentResolverType = {
  component?: ComponentType;
  payload: string | Array<any> | Record<string, any> | undefined;
};
export default abstract class AbstractComponentsResolver {
  protected _componentsConfig: Record<string, any> | undefined;
  public component: Record<string, any> | undefined;

  public setConfig(componentsConfig: Record<string, any>) {
    this._componentsConfig = componentsConfig;
    return this;
  }
  public create(): Record<string, any> {
    return this._resolve(this._components(), this._componentsConfig ?? this._defaults());
  }

  protected _resolve(components: Record<string, any>, config: Record<string, any>) {
    return Object.keys(config).reduce((summ: Record<string, any>, current) => {
      if (!Array.isArray(components[current])) {
        summ[current] = this._resolve(components[current], config[current]);
        return summ;
      }
      summ[current] = this._resolveComponentByName(components[current], config[current]);
      return summ;
    }, {});
  }

  protected _resolveComponentByName(
    componentsList: ComponentType[],
    config?: Record<string, any> | null,
  ) {
    return {
      component: componentsList.find((component) => component.key === config?.key)?.component,
      // переименовать на props?
      payload: config?.props ?? {},
    };
  }
  protected abstract _components(): Record<string, ComponentType[] | unknown>;
  protected _defaults(): { key: string; props: unknown } | unknown {
    return {};
  }
}
