export default abstract class AbstractConfigurator {
  protected _core: Record<string, unknown>;
  protected _options: Record<string, unknown> | null;
  abstract decorators: { name: string; class: any }[] | [];

  public constructor(
    core: Record<string, unknown>,
    options: Record<string, unknown> | null = null,
  ) {
    this._core = core;
    this._options = options;
  }

  public configurate() {
    let service = this._core;
    if (!this._options) {
      return service;
    }
    Object.keys(this._options).forEach((option) => {
      const decorator = this.decorators.find((decorator) => decorator.name === option);
      if (decorator) {
        service = new decorator.class(service, (this._options as Record<string, unknown>)[option]);
      }
    });
    return service;
  }
}
