<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
  <div class="description">{{ t('sendRequest.description') }}</div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.description {
  @include body-2;
  color: $Tertiary_Calm;
  margin-bottom: 1rem;
}
</style>
