<script setup lang="ts">
import MaskedInput from './MaskedInput.vue';
defineProps<{
  label: string;
  modelValue: string | null;
  isValid: boolean | string;
  readonly?: boolean;
  required?: boolean;
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();
</script>

<template>
  <div class="parcel-size__item">
    <MaskedInput
      :label="label"
      :model-value="modelValue"
      :valid-res="isValid"
      :readonly="readonly"
      :required="required"
      @update:model-value="(value: string|null) => emits('update:modelValue', value)"
    />
  </div>
</template>

<style lang="scss" scoped>
.parcel-size__item {
  width: 10rem;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
