<script setup lang="ts">
import { CdekButton, CdekHeadline } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
import RecalculateIcon from './svg/recalculate.svg?component';

const { t } = useI18n();

defineProps<{
  payload: any;
}>();
</script>

<template>
  <div class="final">
    <CdekHeadline size="3">{{ t('final.requestSuccess') }}</CdekHeadline>
    <div class="final__description">{{ t('final.description') }}</div>
    <CdekButton theme="outline" class="button" type="submit">
      <RecalculateIcon class="recalculate-icon" />
      <span class="title-button">{{ t('final.recalculate') }}</span>
    </CdekButton>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.final {
  height: 20rem;
  &__description {
    @include body-2;
    color: rgba(0, 0, 0, 0.6);
  }
}
.button {
  margin: 1rem 0;
  width: 40%;
  @include media-xs {
    width: 100%;
  }
}
.title-button {
  margin-left: 0.5rem;
}
.recalculate-icon {
  stroke: $Primary;
}
</style>
