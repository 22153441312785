import type SelectTariffInterface from '@/services/SelectTariff/SelectTariffInterface';
import type { ServiceListInterface } from '@/interfaces/GetServicesInterface';
import StepControllerService from '@/services/StepControllerService';
import { inject } from 'mini-ioc';
import FieldsDTO from '@/services/FieldsDTO';
import WidgetProps from '@/services/WidgetProps';
import GetService from '@/services/GetService/GetService';
import { analytics } from '@/services/analytics';

export default class SelectTariffService implements SelectTariffInterface {
  public constructor(
    public fields = inject(FieldsDTO),
    public widgetProps = inject(WidgetProps),
    public getService = inject(GetService),
    public stepController = inject(StepControllerService),
  ) {}

  public selectService(id: number, tariff: ServiceListInterface): void {
    if (!id) {
      return;
    }
    this.fields.tariff = tariff;
    this.fields.modeId = id;

    const selectedTariff = this.widgetProps.tariffs.tariffs.find(
      (tariff) => tariff.uuid === this.fields.tariff?.generalServiceId,
    );
    if (!selectedTariff) {
      return;
    }
    this.getService
      .get(selectedTariff, id)
      .then((response) => {
        this.fields.mode = response;
      })
      .catch((e) => {
        console.log(e);
      });
  }
  public submit = () => {
    if (!this.fields.modeId || !this.fields.tariff) {
      return;
    }

    analytics.onSelectTariff();

    this.selectService(this.fields.modeId, this.fields.tariff);
    this.stepController.next('selectTariff');
  };
}
