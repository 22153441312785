import boxIcon from '@/assets/img/box.png';
import envelopeIcon from '@/assets/img/envelope.png';

const optionsMock = [
  {
    id: 1,
    name: 'Конверт',
    icon: envelopeIcon,
    dimension: {
      length: 34,
      width: 27,
      height: 2,
    },
    maxWeight: 0.5,
    description: 'Маленькие предметы: документы, бижутерия, аксессуары',
  },
  {
    id: 2,
    name: 'Короб XS',
    icon: boxIcon,
    dimension: {
      length: 17,
      width: 12,
      height: 9,
    },
    maxWeight: 0.5,
    description: 'Как коробка от смартфона: бижутерия, игрушки, гаджет',
  },
  {
    id: 3,
    name: 'Короб S',
    icon: boxIcon,
    dimension: {
      length: 23,
      width: 19,
      height: 10,
    },
    maxWeight: 2,
    description: 'Как коробка от утюга',
  },
  {
    id: 9,
    name: 'Короб M',
    icon: boxIcon,
    dimension: {
      length: 33,
      width: 25,
      height: 15,
    },
    maxWeight: 5,
    description: 'Чуть больше обувной коробки',
  },
];

export default optionsMock;
