import SelectTariffDecorator from '@/services/SelectTariff/SelectTariffDecorator';
import type { ServiceListInterface } from '@/interfaces/GetServicesInterface';
import type SelectTariffInterface from '@/services/SelectTariff/SelectTariffInterface';
import type AdditionalServiceInterface from '@/interfaces/AdditionServiceInterface';
import { inject } from 'mini-ioc';
import WidgetProps from '@/services/WidgetProps';
export default class WithAdditionServices extends SelectTariffDecorator {
  protected _additionServices: string[];
  public constructor(
    core: SelectTariffInterface,
    additionServices: string[],
    protected _widgetProps = inject(WidgetProps),
  ) {
    super(core);
    this._additionServices = additionServices;
  }
  public selectService(id: number, tariff: ServiceListInterface): void {
    this.fields.mode = null;
    super.selectService(id, tariff);
  }
  public selectAdditionService = async (
    additionalServices: { alias: string; countOfService: string }[],
  ) => {
    const selectedTariff = this._widgetProps.tariffs.tariffs.find(
      (tariff) => tariff.uuid === this.fields?.tariff.generalServiceId,
    );
    const response = await this.getService.get(
      selectedTariff,
      this.fields.modeId,
      additionalServices,
    );
    this.fields.mode = response;
  };
  public get filteredAdditionalServices() {
    const availableAdditionalServices = super.fields.mode
      ?.availableAdditionServices as AdditionalServiceInterface[];
    if (!this._additionServices) {
      return availableAdditionalServices;
    }
    return (availableAdditionalServices ?? []).filter((addService) =>
      this._additionServices.includes(addService.alias),
    );
  }

  public submit = async () => {
    if (!this.fields.modeId || !this.fields.tariff) {
      return;
    }
    this.stepController.next('selectTariff');
  };
}
