<script setup lang="ts">
import { h, computed, type Component } from 'vue';
import Checkbox from '@/components/other/Checkbox.vue';
import DescriptionAdditionService from '@/components/other/additionalServices/DescriptionAdditionService.vue';
import Counter from '@/components/other/Counter.vue';
import InsuranceControlInput from '@/components/other/additionalServices/InsuranceControlInput.vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelValue: boolean | undefined;
  title: string;
  price?: number;
  parameters: string[];
  countOfService: number | string | undefined;
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'selectService'): void;
  (e: 'update:countOfService', value: number): void;
}>();
const { t } = useI18n();

const controlComponent = computed(() => {
  if ((props.parameters ?? []).find((el) => ['count', 'days', 'meters', 'floor'].includes(el))) {
    return h(Counter);
  }
  if ((props.parameters ?? []).find((el) => ['declaredCost'].includes(el))) {
    return h(InsuranceControlInput);
  }

  return h('');
});
</script>

<template>
  <div class="service">
    <slot name="before"></slot>
    <Checkbox
      :model-value="modelValue"
      @change="emits('selectService')"
      @update:model-value="(value: boolean) => emits('update:modelValue', value)"
    >
      <template #description>
        <div class="before-description">
          <slot name="before-description"></slot>
        </div>
        <DescriptionAdditionService :title="title" :price="price" />
      </template>
      <template v-if="modelValue" #control>
        <div class="addition-services__control">
          <div class="control-description">
            {{
              props.parameters.length
                ? t(`selectTariff.additionalServices.parameters.${props.parameters[0]}`)
                : ''
            }}
          </div>
          <div class="control">
            <component
              :is="controlComponent as Component"
              :model-value="countOfService"
              @update:model-value="(value: number) => emits('update:countOfService', value)"
              @change="emits('selectService')"
            />
          </div>
        </div>
      </template>
    </Checkbox>
    <slot name="after"></slot>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.addition-services__control {
  display: flex;
  @include media-xs {
    flex-basis: 100%;
    justify-content: flex-end;
    gap: 1rem;
  }
  .control {
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include media-xs {
      width: fit-content;
    }
  }
  .control-description {
    color: $Bottom_60;
    display: flex;
    align-items: flex-start;
    @include media-xs {
      align-items: center;
    }
  }
}
.before-description {
  @include media-xs {
    display: none;
  }
}
</style>
