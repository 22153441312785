<script setup lang="ts">
import ParcelSizeItem from '@/components/other/ParcelSizeItem.vue';
import { useI18n } from 'vue-i18n';
import { inject, toRefs } from 'vue';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import type Container from 'mini-ioc';
import FieldsDTO from '@/services/FieldsDTO';
import { applyPackageValidators } from './validatorsUtils';

const { t } = useI18n();

defineProps<{
  readonly?: {
    width?: boolean;
    height?: boolean;
    length?: boolean;
    weight?: boolean;
  };
}>();
const container = inject('container') as Container;
const fields = container.get(FieldsDTO);

const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.packages = {
  width: null,
  height: null,
  length: null,
  weight: null,
};

const packagesRefs = toRefs(fields.packages);

const lengthValidator = validatorsBuilder.build(packagesRefs.length).number().numberNotNull();
const widthValidator = validatorsBuilder.build(packagesRefs.width).number().numberNotNull();
const heightValidator = validatorsBuilder.build(packagesRefs.height).number().numberNotNull();
const weightValidator = validatorsBuilder.build(packagesRefs.weight).number().numberNotNull();

const widgetProps = inject('WidgetCalc') as any;
const { packageLimits } = widgetProps.configs;

applyPackageValidators(packageLimits, packagesRefs, {
  lengthValidator,
  widthValidator,
  heightValidator,
  weightValidator,
});
</script>

<template>
  <div class="parcel-size">
    <ParcelSizeItem
      v-model="fields.packages.length"
      :label="t('calculate.packages.length')"
      :is-valid="lengthValidator.validated.value"
      :error-message="t('inputError')"
      :readonly="readonly?.length"
    />
    <ParcelSizeItem
      v-model="fields.packages.width"
      :label="t('calculate.packages.width')"
      :is-valid="widthValidator?.validated.value"
      :error-message="t('inputError')"
      :readonly="readonly?.width"
    />
    <ParcelSizeItem
      v-model="fields.packages.height"
      :label="t('calculate.packages.height')"
      :is-valid="heightValidator?.validated.value"
      :error-message="t('inputError')"
      :readonly="readonly?.height"
    />
    <ParcelSizeItem
      v-model="fields.packages.weight"
      :label="t('calculate.packages.weight')"
      :is-valid="weightValidator?.validated.value"
      :error-message="t('inputError')"
      :readonly="readonly?.weight"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.parcel-size {
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
</style>
