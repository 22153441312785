<script setup lang="ts">
import ArrowRight from './svg/arrow-right.svg?component';
import { useI18n } from 'vue-i18n';
import useInjectContainer from '@/composables/useInjectContainer';
import StepControllerService from '@/services/StepControllerService';
const { t } = useI18n();
const container = useInjectContainer();
const stepController = container.get(StepControllerService);
</script>

<template>
  <div class="link-back" @click="stepController.prev">
    <ArrowRight class="link-back__arrow-right" />
    <span class="link-back__text">{{ t('selectTariff.linkBack') }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.link-back {
  color: $Primary;
  @include body-2;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
  &__arrow-right {
    margin-right: 0.5rem;
    stroke: $Primary;
  }
  &__text {
    color: inherit;
  }
}
</style>
