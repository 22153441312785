import axios from 'axios';
import type { CityResponseInterface } from '@/interfaces/CityResponseInterface';
import type CityAutocompleteInterface from './CityAutocompleteInterface';
import { inject } from 'mini-ioc';
import WidgetProps from '@/services/WidgetProps';
export default class CityAutocompleteService implements CityAutocompleteInterface {
  public uri = '/api/v1/city';

  public constructor(public widgetProps = inject(WidgetProps)) {}

  public async getCity(query: string) {
    const request = await this.request(query);
    const response = await request.data;

    return response?.data?.map((item: CityResponseInterface) => {
      return this.response(item);
    });
  }
  public cityFrom(query: string) {
    return this.getCity(query);
  }
  public cityTo = (query: string) => {
    return this.getCity(query);
  };

  public async request(query: string) {
    return axios.get(this.widgetProps.host + this.uri, {
      headers: {
        'X-User-Lang': this.widgetProps.language,
      },
      params: {
        query: query,
        limit: 5,
      },
    });
  }

  public response(response: CityResponseInterface) {
    return {
      value: response,
      title: response.name,
    };
  }
}
