import StepControllerService from '@/services/StepControllerService';
import { reactive } from 'vue';
import { inject } from 'mini-ioc';

export default class CacheKeysService {
  protected _cacheKeys: Record<string, number>;
  public constructor(protected _stepController = inject(StepControllerService)) {
    const steps = this._stepController.steps;
    this._cacheKeys = steps.reduce((acc: Record<string, any>, cur) => {
      acc[cur] = 0;
      return acc;
    }, reactive({}));
  }

  public get cacheKeys() {
    return this._cacheKeys;
  }
  public nextUp = () => {
    const currentStepIndex = this._stepController.steps.findIndex(
      (step) => step === this._stepController.step,
    );
    const nextEl =
      this._stepController.steps[currentStepIndex + 1] ?? this._stepController.steps[0];
    this._cacheKeys[nextEl]++;
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public allUp = () => {};
}
