<script setup lang="ts">
interface Item {
  title: string;
  value: string;
}
defineProps<{
  modelValue: string;
  items: Array<Item>;
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();
</script>

<template>
  <div class="segment">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="['segment__button', item.value == modelValue ? 'segment__button_active' : '']"
      @click="emits('update:modelValue', item.value)"
    >
      {{ item.title.toUpperCase() }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.segment {
  width: auto;
  display: flex;
  justify-content: space-around;
  background-color: $Surface_Neutral;
  padding: 4px;
  border-radius: 10px;
  margin: 1rem 0;
  &__button {
    color: $Bottom_60;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.55px;
    &_active {
      background-color: $Peak;
      color: $Primary_Active;
      padding: 2px 8px 2px 8px;
      border-radius: 8px;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
