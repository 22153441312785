<script setup lang="ts"></script>

<template>
  <div class="basis-element">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.basis-element {
  width: 100%;
  background-color: $Tertiary_5;
  border-radius: 8px;
  padding-inline: 14px;
  padding-block: 16px;
  box-sizing: border-box;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
</style>
