<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, inject } from 'vue';
import { CdekButton, CdekSelect } from '@cdek-ui-kit/vue';
import type Container from 'mini-ioc';
import FieldsDTO from '@/services/FieldsDTO';
import PackagesSelect from './packagesSelect/PackagesSelect.vue';
import optionsMock from './optionsMock';
import type { CityResponseInterface } from '@/interfaces/CityResponseInterface';

interface ICalculateLTL {
  title: string;
  cityTips: any;
}

defineProps<ICalculateLTL>();

const { t } = useI18n();

const container = inject('container') as Container;
const fields = container.get(FieldsDTO);

fields.route = {
  city: {
    code: null,
  },
};

fields.packages = {
  width: null,
  height: null,
  length: null,
  weight: null,
};

const isNotFilled = computed(
  () =>
    !fields.route.city.code ||
    !fields.packages.width ||
    !fields.packages.height ||
    !fields.packages.length ||
    !fields.packages.weight,
);

const calcUrl = import.meta.env.VITE_ORDER_CREATE_URL || 'https://cdek.ru/m/createOrder';

const onSubmit = () => {
  const {
    route: {
      city: { code },
    },
    packages: { width, height, length, weight },
  } = fields;

  const params = new URLSearchParams({
    from: code,
    to: code,
    width,
    height,
    length,
    weight,
    fourHoursMode: 'true',
  });

  window.location.href = `${calcUrl}?${params}`;
};

const cities = [
  {
    uuid: '01581370-81f3-4322-9a28-3418adfabd97',
    code: 44,
    name: 'Россия, Москва, Москва, Москва',
    shortName: 'Москва',
  },
  {
    uuid: '901944f4-dbd1-4308-9cc7-d1fbbd858804',
    code: 137,
    name: 'Россия, Санкт-Петербург, Санкт-Петербург, Санкт-Петербург',
    shortName: 'Санкт-Петербург',
  },
];

const getCityTitle = (item: CityResponseInterface) => {
  return item.shortName;
};

const getCityValue = (item: CityResponseInterface) => {
  return item.code;
};
</script>

<template>
  <div class="calculator-route">
    <div class="calculator-route__inputs">
      <CdekSelect
        v-model="fields.route.city.code"
        :items="cities"
        :get-title="getCityTitle"
        :get-value="getCityValue"
        style="width: 100%; text-align: left"
        label="Выберите город"
      ></CdekSelect>
      <PackagesSelect
        v-model="fields.packages"
        style="width: 100%"
        :value="null"
        :options="optionsMock"
        placeholder="Размер посылки"
        :has-error="false"
      />
    </div>
  </div>
  <div class="submit">
    <cdek-button :disabled="isNotFilled" @click="onSubmit">{{
      t('calculate.buttonSubmit')
    }}</cdek-button>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.calculator-route {
  &__inputs {
    display: flex;
    gap: 1rem;

    @include media-md {
      flex-wrap: wrap;
    }

    :deep(.green-input__tip:empty) {
      display: none;
    }
  }
}
.submit {
  width: 100%;
  margin-inline: auto;

  @include media-min-lg {
    width: 350px;
  }
}
</style>
