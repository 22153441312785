<script setup lang="ts">
import useInjectContainer from '@/composables/useInjectContainer';
import FinalComponentResolver from '@/services/Components/FinalComponentResolver';
import type { Component } from 'vue';
import StepControllerService from '@/services/StepControllerService';
import CacheKeysService from '@/services/CacheKeysService';
import { CdekForm } from '@cdek-ui-kit/vue';

const container = useInjectContainer();
const final = container.get(FinalComponentResolver).create();

const stepController = container.get(StepControllerService);
const cacheService = container.get(CacheKeysService);

const recalc = () => {
  cacheService.nextUp();
  stepController.reset();
};
</script>

<template>
  <CdekForm @submit="recalc">
    <component
      :is="final.final.component as Component"
      v-if="final.final.component"
      :payload="final.final.payload"
    />
  </CdekForm>
</template>
