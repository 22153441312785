import { computed } from 'vue';
import type ValidatorService from '@/services/Validator/ValidatorService';
import { useI18n } from 'vue-i18n';

export const addValidator = (
  validator: ValidatorService,
  valueRef: any,
  maxLimit: number | undefined,
  minLimit: number | undefined,
  unit: string,
) => {
  const { t } = useI18n();

  validator.custom(
    computed(() => {
      const value = Number(valueRef.value);
      const isValidMax = maxLimit !== undefined ? value <= maxLimit : true;
      const isValidMin = minLimit !== undefined ? value >= minLimit : true;
      return isValidMax && isValidMin;
    }),
    [
      maxLimit !== undefined ? `${t('calculate.packages.notMoreThan')} ${maxLimit} ${unit}` : '',
      minLimit !== undefined ? `${t('calculate.packages.notLessThan')} ${minLimit} ${unit}` : '',
    ]
      .filter(Boolean)
      .join(', '),
  );
};

export const applyPackageValidators = (
  packageLimits: any,
  packagesRefs: any,
  validators: {
    lengthValidator: ValidatorService;
    widthValidator: ValidatorService;
    heightValidator: ValidatorService;
    weightValidator: ValidatorService;
  },
) => {
  const { t } = useI18n();

  if (!packageLimits) return;

  const { length, width, height, weight } = packageLimits;

  if (length) {
    const { max: maxLength, min: minLength } = length;
    addValidator(
      validators.lengthValidator,
      packagesRefs.length,
      maxLength,
      minLength,
      t('calculate.packages.cm'),
    );
  }

  if (width) {
    const { max: maxWidth, min: minWidth } = width;
    addValidator(
      validators.widthValidator,
      packagesRefs.width,
      maxWidth,
      minWidth,
      t('calculate.packages.cm'),
    );
  }

  if (height) {
    const { max: maxHeight, min: minHeight } = height;
    addValidator(
      validators.heightValidator,
      packagesRefs.height,
      maxHeight,
      minHeight,
      t('calculate.packages.cm'),
    );
  }

  if (weight) {
    const { max: maxWeight, min: minWeight } = weight;
    addValidator(
      validators.weightValidator,
      packagesRefs.weight,
      maxWeight,
      minWeight,
      t('calculate.packages.kg'),
    );
  }
};
