<script setup lang="ts">
import useInjectContainer from '@/composables/useInjectContainer';
import TariffsService from '@/services/TariffsService';
import CalculateService from '@/services/Calculate/CalculateService';
import { computed } from 'vue';
import Tariff from '../shared/Tariff.vue';
import { useI18n } from 'vue-i18n';
import StepControllerService from '@/services/StepControllerService';
import FieldsDTO from '@/services/FieldsDTO';
import type { CityResponseInterface } from '@/interfaces/CityResponseInterface';

defineProps<{
  payload: any;
}>();

const { t } = useI18n();

const container = useInjectContainer();
const calculateService = container.get(CalculateService);
const tariffsService = container.get(TariffsService);
const fields = container.get(FieldsDTO);

const stepController = container.get(StepControllerService);

tariffsService.tariffs = calculateService.tariffs?.serviceList ?? [];

const modesLTL = computed(() => {
  // как я понимаю вседа один тариф возвращается
  return tariffsService.tariffs?.[0].modeDetails || [];
});

const getShortNameFromString = (name?: string | null) => {
  if (name) {
    const split = name.split(', ');

    if (split.length > 1) {
      return split[split.length - 1];
    }
  }

  return name;
};

const getShortName = (city?: CityResponseInterface) => {
  return city?.shortName ?? getShortNameFromString(city?.name);
};

const routeText = computed(() => {
  const { cityFrom, cityTo } = fields.route;

  const shortCityFromName = getShortName(cityFrom);

  const shortCityToName = getShortName(cityTo);

  if (shortCityFromName && shortCityToName) {
    return `${shortCityFromName} - ${shortCityToName}`;
  }

  return '';
});
</script>

<template>
  <div class="ltl">
    <p class="ltl__route">{{ routeText }}</p>
    <div class="ltl__change_directions" @click="stepController.prev">
      {{ t('selectTariff.changeDirection') }}
    </div>

    <div class="ltl__tariff_list">
      <Tariff v-for="tariff in modesLTL" :key="tariff.tariffEc4Id" :tariff="tariff" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.ltl {
  text-align: left;

  &__route {
    margin-bottom: 4px;

    @include headline-5;

    @include media-min-lg {
      @include headline-4;
    }
  }

  &__change_directions {
    color: $Primary_Active;
    margin-bottom: 24px;
    cursor: pointer;
  }

  &__tariff_list {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-min-lg {
      gap: 0;
    }
  }
}
</style>
