<script setup lang="ts">
import { CdekButton } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

interface iButtonSubmitProps {
  loading?: boolean;
}

defineProps<iButtonSubmitProps>();
</script>

<template>
  <div class="submit">
    <cdek-button type="submit" :loading="loading">{{ t('calculate.buttonSubmit') }}</cdek-button>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.submit {
  width: 100%;

  @include media-min-lg {
    width: 232px;
  }
}
</style>
