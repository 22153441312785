<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import { CdekAutocomplete, CdekSpinner } from '@cdek-ui-kit/vue';
import { TextHighlightService } from '../../services/TextHighlightService';
import type { CityResponseInterface } from '../../interfaces/CityResponseInterface';
import { useI18n } from 'vue-i18n';
import Tips from '@/components/other/Tips/Tips.vue';
import type { ITip } from '@/components/other/Tips/types';

const { t } = useI18n();

const props = defineProps<{
  service: CallableFunction;
  label: string;
  modelValue: any;
  cityTips?: ITip[];
  fullWidth?: boolean;
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', value: CityResponseInterface): void;
}>();

const autocompleteRef = ref();

const loading = ref(false);

onMounted(() => {
  new TextHighlightService(autocompleteRef.value).observe();
});

const updateModelValue = (value: CityResponseInterface) => {
  emits('update:modelValue', value);
};

const items = ref(<CityResponseInterface[]>[]);

const selectTip = (city: CityResponseInterface) => {
  items.value = [city];
  updateModelValue(city);
};

const getValue = (item: CityResponseInterface) => {
  return item.code;
};

const getTitle = (item: CityResponseInterface) => {
  return item.name;
};

const fetchItems = async (query: string) => {
  loading.value = true;
  const response = await props.service(query);

  items.value = response.map((item: any) => {
    return item.value;
  });

  loading.value = false;

  return items.value;
};

const numberValue = computed(() => {
  return props?.modelValue?.code || null;
});

const selectItem = async (item: CityResponseInterface) => {
  await nextTick();
  updateModelValue(item);
};
</script>

<template>
  <div
    ref="autocompleteRef"
    class="city-autocomplete-v2"
    :class="{ 'city-autocomplete-v2__full-width': fullWidth }"
  >
    <CdekAutocomplete
      :label="label"
      :items="items"
      :fetch-items="fetchItems"
      :model-value="numberValue"
      name="cityAutocomplete"
      class="city-autocomplete__control"
      :clearable="true"
      :get-value="getValue"
      :get-title="getTitle"
      required
      @update:model-value="updateModelValue"
      @keydown.enter.stop.prevent
      @select="selectItem"
    >
      <template #not-found>
        <span>{{ t('calculate.notFoundCity') }}</span>
      </template>

      <template #icons-right>
        <CdekSpinner v-if="loading" />
      </template>
    </CdekAutocomplete>

    <Tips
      v-if="cityTips"
      class="city-autocomplete-v2__tips"
      :city-tips="cityTips"
      @select-tip="selectTip"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.city-autocomplete-v2 {
  width: 100%;

  @include media-min-lg {
    max-width: 368px;
  }

  &__full-width {
    @include media-min-lg {
      max-width: unset;
    }
  }
}
.green-input__right-icon {
  display: flex;
  position: absolute;
  right: 14px;
}
</style>
<style lang="scss">
@import '@cdek-ui-kit/vue/scss';
.highlight {
  background-color: $Textmatch;
}
</style>
