import { reactive } from 'vue';
import type { ServiceListInterface } from '@/interfaces/GetServicesInterface';

export default class FieldsDTO {
  public constructor(protected _fields: Record<string, any> = reactive({})) {}

  public getValues() {
    return this._fields;
  }

  public get route() {
    return this._fields.route;
  }
  public set route(value: Record<string, any>) {
    this._fields.route = value;
  }
  public get packages() {
    return this._fields.packages;
  }
  public set packages(value: Record<string, any>) {
    this._fields.packages = value;
  }
  public get tariff() {
    return this._fields.tariff;
  }
  public set tariff(value: ServiceListInterface | null) {
    this._fields.tariff = value;
  }
  public get modeId() {
    return this._fields.modeId;
  }
  public set modeId(value: number | null) {
    this._fields.modeId = value;
  }
  public get mode() {
    return this._fields.mode;
  }
  public set mode(value: Record<string, any> | null) {
    this._fields.mode = value;
  }
  public get client() {
    // eslint-disable-next-line no-prototype-builtins
    if (!this._fields.hasOwnProperty('client')) {
      this._fields.client = reactive({});
    }
    return this._fields.client;
  }
  public set client(value: Record<string, any>) {
    this._fields.client = value;
  }
  public get other() {
    return this._fields.other;
  }
  public set other(value: Record<string, any>) {
    this._fields.other = {
      ...this._fields.other,
      ...value,
    };
  }
}
