<script setup lang="ts">
import type WidgetPropsInterface from '@/interfaces/WidgetPropsInterface';
import { CdekWidget } from '@cdek-ui-kit/vue';
import useCustomColor from '@/composables/useCustomColor';
import CalculateTemplateStandard from '@/components/Templates/Calculate/Standard.vue';
import SelectTariffTemplateStandard from '@/components/Templates/SelectTariff/Standard.vue';
import { inject, onBeforeUnmount } from 'vue';
import type Container from 'mini-ioc';
import FieldsDTO from '@/services/FieldsDTO';
import StepControllerService from '@/services/StepControllerService';
import SendRequestTemplateStandard from '@/components/Templates/SendRequest/Standard/SendRequestTemplateStandard.vue';
import FinalStandardTemplate from '@/components/Templates/Final/FinalStandardTemplate.vue';

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import BreadcrumbsComponentResolver from '@/services/Components/BreadcrumbsComponentResolver';
import { type Component } from 'vue';
import CacheKeysService from './services/CacheKeysService';
import CalculateService from '@/services/Calculate/CalculateService';
const props = defineProps<WidgetPropsInterface>();

const container = inject('container') as Container;
const fields = container.get(FieldsDTO);
const stepController = container.get(StepControllerService);
const cacheKeys = container.get(CacheKeysService).cacheKeys;
const calculateService = container.get(CalculateService);

if (props.color) {
  useCustomColor(props.color as string);
}
const env_mode = import.meta.env.MODE;

const breadcrumbs = container.get(BreadcrumbsComponentResolver).create();

onBeforeUnmount(() => {
  stepController.reset();
  calculateService.reset();
});
</script>

<template>
  <CdekWidget class="app">
    <component
      :is="breadcrumbs.component as Component"
      v-if="breadcrumbs.component"
      :payload="breadcrumbs.payload"
    />
    <div :key="cacheKeys.calculate">
      <KeepAlive>
        <CalculateTemplateStandard v-if="stepController.step === 'calculate'" />
      </KeepAlive>
    </div>
    <div :key="cacheKeys.selectTariff">
      <KeepAlive>
        <SelectTariffTemplateStandard v-if="stepController.step === 'selectTariff'" />
      </KeepAlive>
    </div>
    <div :key="cacheKeys.sendRequest">
      <KeepAlive>
        <SendRequestTemplateStandard v-if="stepController.step === 'sendRequest'" />
      </KeepAlive>
    </div>
    <div :key="cacheKeys.final">
      <KeepAlive>
        <FinalStandardTemplate v-if="stepController.step === 'final'" />
      </KeepAlive>
    </div>
  </CdekWidget>
  <vue-json-pretty v-if="env_mode === 'development'" :data="fields.getValues()" />
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';

#widget-calc {
  min-height: 582px;
}

.app {
  @include media-sm {
    border-radius: 0;
  }
}
</style>
