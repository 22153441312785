<template>
  <CdekInput
    v-model="fields.client.phone"
    v-maska
    :label="t('sendRequest.phone')"
    placeholder="+0 (000) 000 00 00"
    data-maska="+# (###) ###-##-##"
    :valid-res="phoneValidator.validated.value"
  />
</template>

<script setup lang="ts">
import { CdekInput } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
import { inject, toRef } from 'vue';
import { vMaska } from 'maska';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
const { t } = useI18n();

const container = useInjectContainer();
const fields = container.get(FieldsDTO);
const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.client.phone = null;

const phoneRef = toRef(fields.client, 'phone');

const phoneValidator = validatorsBuilder.build(phoneRef).phone();
</script>
