import type SendRequestInterface from '@/services/sendRequest/SendRequestInterface';

export default class SendRequestDecorator implements SendRequestInterface {
  protected _core: SendRequestInterface;

  public constructor(core: SendRequestInterface) {
    this._core = core;
  }

  public get uri() {
    return this._core.uri;
  }

  public send = async () => this._core.send();
  public getRequestBody(): Record<string, any> {
    return this._core.getRequestBody();
  }
}
