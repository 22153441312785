window.dataLayer = window.dataLayer || [];

const sendCustomEvent = (eventName: string) => {
  const customEvent = new CustomEvent(eventName);

  document.dispatchEvent(customEvent);
};

export const analytics = {
  onCalculate: () => {
    window.dataLayer?.push({ event: 'calculate' });
    sendCustomEvent('widgetCalc:calculate');
  },
  onSelectTariff: () => {
    window.dataLayer?.push({ event: 'tariff' });
    sendCustomEvent('widgetCalc:selectTariff');
  },
  onContactsFill: () => {
    window.dataLayer?.push({ event: 'contact_data' });
    sendCustomEvent('widgetCalc:contactsFill');
  },
  onContactsSend: () => {
    window.dataLayer?.push({ event: 'contact_send' });
    sendCustomEvent('widgetCalc:contactsSend');
  },
};
