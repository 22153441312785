import { createI18n } from 'vue-i18n';
import langs from '@/i18n';

export default class I18nAppInit {
  public createLocales(
    locale: string | undefined,
    messages: Record<string, any> | undefined,
    fallbackLocale?: string,
  ) {
    return createI18n({
      legacy: false,
      locale: locale ?? 'rus',
      fallbackLocale: fallbackLocale ?? 'rus',
      messages: {
        ...this._mergeLocales(langs, messages ?? {}),
      },
    });
  }
  private _mergeLocales(defaultLocales: Record<string, any>, customLocales: Record<string, any>) {
    const result: Record<string, any> = {};
    if (typeof customLocales !== 'object') {
      return customLocales;
    }
    Object.keys(customLocales).forEach((key) => {
      if (defaultLocales.hasOwnProperty(key)) {
        result[key] = this._mergeLocales(defaultLocales[key], customLocales[key]);
        return;
      }
      result[key] = customLocales[key];
    });
    return {
      ...defaultLocales,
      ...result,
    };
  }
}
