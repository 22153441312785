import ValidatorService from '@/services/Validator/ValidatorService';
import type { Ref } from 'vue';

export default class ValidatorsBuilder {
  protected _validators: ValidatorService[] = [];
  public build = (value: Ref<string | number | null | undefined>) => {
    if (!value) {
      throw new Error('Валидатору не передана ссылка на значение!');
    }
    const validator = new ValidatorService(value);
    this._validators.push(validator);
    return validator;
  };

  public get isValid() {
    let valid = true;

    this._validators.forEach((validator) => {
      if (typeof validator.validated.value === 'string') {
        valid = false;
      }
    });

    return valid;
  }
}
