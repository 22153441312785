import GetServiceDecorator from '@/services/GetService/GetServiceDecorator';
import type TariffInterface from '@/interfaces/TariffInterface';
import axios from 'axios';

export default class WithAdditionServices extends GetServiceDecorator {
  protected _additionalServicesParams = [
    {
      alias: 'insurance',
      parameter: 'declaredCost',
    },
    {
      alias: 'bubbleWrap',
      parameter: 'meters',
    },
    {
      alias: 'warehousing',
      parameter: 'days',
    },
    {
      alias: 'repeatDelivery',
      parameter: null,
    },
  ];

  public async get(
    tariff: TariffInterface,
    tariffEc4Id: string | number,
    additionalServices: { alias: string; countOfService: string }[] = [],
  ) {
    if (!tariff || !tariffEc4Id) {
      return Promise.reject();
    }
    const response = await axios.post(
      super.widgetProps.host + super.uri,
      this.getRequestBody(tariff, tariffEc4Id, additionalServices),
      {
        headers: {
          'X-User-Locale': super.language,
        },
      },
    );
    if (response.status === 200 && response.data.data.ec4Id) {
      return response.data.data;
    }
    throw new Error('bad request');
  }
  public getRequestBody(
    service: TariffInterface,
    tariffEc4Id: string | number,
    additionalServices: { alias: string; countOfService: string }[] = [],
  ) {
    return {
      sender: {
        cityId: this.fields.route.cityFrom?.uuid,
        contragentType: this.widgetProps.tariffs.sender.contragentType ?? 'FIZ',
      },
      receiver: {
        cityId: this.fields.route.cityTo?.uuid,
        contragentType: this.widgetProps.tariffs.receiver.contragentType ?? 'FIZ',
      },
      payer: {
        contractId: this.widgetProps.tariffs.payer.contractId ?? null,
        payerType: this.widgetProps.tariffs.payer.payerType ?? 'sender',
      },
      orderParam: {
        orderTypeCode: this.widgetProps.tariffs.orderParam.orderTypeCode ?? 1,
        additionalOrderTypeCodes:
          this.widgetProps.tariffs.orderParam.additionalOrderTypeCodes ?? [],
      },
      interfaceCode: this.widgetProps.tariffs.interfaceCode ?? 'ec5_front',
      currencyMark: this.widgetProps.currencyMark,
      packages: [
        {
          length: Number(this.fields.packages.length),
          width: Number(this.fields.packages.width),
          height: Number(this.fields.packages.height),
          weight: Number(this.fields.packages.weight),
        },
      ],
      filters: {
        calcServicesId: [service.uuid],
      },
      tariffTypeId: tariffEc4Id,
      calculateAdditionalServices: this._getAdditionServices(additionalServices),
    };
  }
  protected _getAdditionServices = (
    selectedAddServices: { alias: string; countOfService: string }[],
  ) => {
    if (!selectedAddServices.length) {
      return;
    }
    return selectedAddServices.map((addService) => {
      const parameter = this._additionalServicesParams.find(
        (param) => param.alias === addService.alias,
      ).parameter;
      const individualParameter = {};
      individualParameter[parameter] = addService.countOfService;
      return {
        alias: addService.alias,
        individualParameter,
      };
    });
  };
}
