import type CalculateInterface from '@/services/calculate/CalculateInterface';
import type { GetServicesResponseInterface } from '@/interfaces/GetServicesInterface';
import type { Ref } from 'vue';
import { ref } from 'vue';
import StepControllerService from '@/services/StepControllerService';
import CacheKeysService from '@/services/CacheKeysService';
import { inject } from 'mini-ioc';
import GetServicesService from '@/services/GetServices/GetServicesService';
import FieldsDTO from '@/services/FieldsDTO';
import { analytics } from '@/services/analytics';

export default class CalculateService implements CalculateInterface {
  protected _tariffs: Ref<GetServicesResponseInterface | undefined> = ref();
  protected _notFoundTariffs: Ref<boolean> = ref(false);

  public constructor(
    protected _getServices = inject(GetServicesService),
    protected _stepController = inject(StepControllerService),
    protected _fields = inject(FieldsDTO),
    protected _cacheKeysService = inject(CacheKeysService),
  ) {}

  public get tariffs(): undefined | GetServicesResponseInterface {
    return this._tariffs.value;
  }
  public set tariffs(tariffs: undefined | GetServicesResponseInterface) {
    this._tariffs.value = tariffs;
  }
  public get notFoundTariffs() {
    return this._notFoundTariffs.value;
  }

  public reset() {
    this._notFoundTariffs.value = false;
    this._tariffs.value = undefined;
  }

  public calculate = () => {
    this._notFoundTariffs.value = false;
    this._cacheKeysService.nextUp();
    this._getServices
      .get()
      .then((response) => {
        analytics.onCalculate();

        this._fields.tariff = null;
        this._fields.modeId = null;
        this._fields.mode = null;

        this.reset();
        this._tariffs.value = response;

        this._stepController.next('calculate');
      })
      .catch((e) => {
        console.log(e);
        this._tariffs.value = undefined;
        this._notFoundTariffs.value = true;
      });
  };
}
