import type { ServiceListInterface, ModeDetailsInterface } from '@/interfaces/GetServicesInterface';
import DeliveryModesDTO from '@/services/DeliveryModesDTO';
import { inject } from 'mini-ioc';

interface ModeDetailsInterfaceWithPoints extends ModeDetailsInterface {
  deliveryPoints?: Record<string, any>;
}
export default class TariffsService {
  protected _tariffs: ServiceListInterface[] | undefined;
  public constructor(public deliveryModesDTO = inject(DeliveryModesDTO)) {}
  public set tariffs(tariffs: ServiceListInterface[]) {
    this._tariffs = tariffs;
    this._withDeliveryPoints();
  }
  public get tariffs() {
    return this._tariffs as ServiceListInterface[];
  }

  public get entrypoints() {
    return this._points(this._tariffs as ServiceListInterface[], 'entrypoint');
  }

  public filteredTariffsByEntrypoint(entrypoint: string) {
    const tariffs = this._tariffs?.map((tariff) => {
      return {
        ...tariff,
        modeDetails: tariff.modeDetails.filter(
          (mode: ModeDetailsInterfaceWithPoints) => mode.deliveryPoints?.entrypoint == entrypoint,
        ),
      };
    });
    return {
      filteredTariffs: tariffs,
      endpoints: this._points(tariffs as ServiceListInterface[], 'endpoint'),
    };
  }

  protected _withDeliveryPoints = () => {
    this._tariffs?.forEach((tariff) => {
      tariff.modeDetails.forEach((mode: ModeDetailsInterfaceWithPoints) => {
        mode.deliveryPoints = this.deliveryModesDTO.getDeliveryModes(mode.modeCode);
      });
    });
  };

  protected _points(tariffs: ServiceListInterface[], pointName: string) {
    const result = new Set();
    tariffs.forEach((tariff) => {
      tariff.modeDetails.forEach((mode: ModeDetailsInterfaceWithPoints) => {
        if (!mode.deliveryPoints) {
          return;
        }
        result.add(mode.deliveryPoints[pointName]);
      });
    });
    return [...result];
  }
}
