<script setup lang="ts">
const props = defineProps<{
  modelValue: any;
  value: any;
  title?: string | number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
}>();
</script>

<template>
  <label class="green-radio green-radio_form-control">
    <input
      type="radio"
      name="radio"
      :checked="modelValue === value"
      @click="() => emit('update:modelValue', props.value)"
    />
    <span class="checkmark green-radio"></span>
    <div class="title">
      <slot name="title"></slot>
    </div>
  </label>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.green-radio_form-control {
  width: 100%;
  @include body-1;
  display: flex;
  gap: 0.5rem;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 0.5rem;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px $Primary solid;
    background-color: transparent;
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
  }
  input:checked ~ .checkmark {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 6px $Primary solid;
    box-sizing: border-box;
  }
  .title {
    width: 100%;
    margin-left: 0.5rem;
  }
}
</style>
