import { defineAsyncComponent } from 'vue';
import AbstractComponentsResolver from '@/services/Components/AbstractComponentsResolver';

export default class BreadcrumbsComponentResolver extends AbstractComponentsResolver {
  protected _components() {
    return {
      breadcrumbs: [
        {
          key: 'standard',
          component: defineAsyncComponent(() => import('@/components/Breadcrumbs/Standard.vue')),
        },
      ],
    };
  }
}
