import { computed, type ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';

export default class ValidatorService {
  private _standardErrorText = useI18n().t('inputError');
  private _nullable = false;
  private _validated: Record<string, any> = {};

  private _addValidation(name: string, validationFn: () => boolean) {
    this._validated[name] = {
      valid: computed(validationFn),
      errorText: this._standardErrorText,
    };
    return this;
  }

  public constructor(private _value: any) {}

  public nullable = () => {
    this._nullable = true;
    return this;
  };

  public number = () => this._addValidation('isNumber', () => !isNaN(this._value.value));

  public numberNotNull = () =>
    this._addValidation('numberIsNotNull', () => Number(this._value.value) !== 0);

  public phone = () =>
    this._addValidation('isPhone', () =>
      /\+\d \(\d{3}\) \d{3}-\d{2}-\d{2}/.test(this._value.value),
    );

  public email = () =>
    this._addValidation('isEmail', () =>
      /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/.test(this._value.value),
    );

  public route = () => this._addValidation('isRoute', () => Boolean(this._value.value?.uuid));

  public truth = () => this._addValidation('isTrue', () => Boolean(this._value.value));

  public custom = (valid: ComputedRef, errorText: string) => {
    this._validated.isCustom = { valid, errorText };
    return this;
  };

  public get validated() {
    return computed(() => {
      if ((this._nullable && !this._value.value) || this._value.value === null) {
        return true;
      }
      if (!Object.keys(this._validated).length) {
        return Boolean(this._value.value) || this._standardErrorText;
      }
      for (const { valid, errorText } of Object.values(this._validated)) {
        if (!valid.value) return errorText;
      }
      return true;
    });
  }
}
