<script setup lang="ts">
import Description from './Description.vue';
import TotalPrice from './TotalPrice.vue';
import { computed } from 'vue';
import type { CalculatedAdditionalServicesInterface } from '@/interfaces/GetServiceResponceInterface';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';

defineProps<{
  payload: any;
}>();

const container = useInjectContainer();
const fields = container.get(FieldsDTO);

const parcelInformation = computed(() => {
  const packageItem = {
    title: 'Данные грузоместа',
    items: [
      {
        title: 'Вес',
        value: `${fields.packages.weight} кг`,
      },
      {
        title: 'Габариты',
        value: `${fields.packages.length} × ${fields.packages.width} × ${fields.packages.height} см`,
      },
      {
        title: 'Количество грузомест',
        value: `${Number(fields.packages.parcelCount).toLocaleString()}`,
      },
    ],
  };
  const insurance = (fields.mode?.calculatedAdditionalServices ?? []).find(
    (service: CalculatedAdditionalServicesInterface) => service.alias === 'insurance',
  );
  const declaredCost = fields.other.selectedAdditionalServices.find(
    (service: CalculatedAdditionalServicesInterface) => service.alias === 'insurance',
  )?.countOfService;
  if (insurance) {
    packageItem.items.push({
      title: 'Ценность груза',
      value: `${Number(declaredCost).toLocaleString()} ₽`,
    });
  }
  return [packageItem];
});

const marketplace = computed(() => {
  return {
    title: `Получатель: ${fields.route.cityTo?.city}, ${fields.route.cityTo?.marketplace}`,
  };
});

const tariff = computed(() => {
  const modeName = fields.tariff.modeDetails.find((mode) => mode.tariffEc4Id === fields.mode.ec4Id);
  return {
    title: `Тариф (${fields.tariff.serviceName} ${modeName.modeName})`,
    value: `${Number(fields.mode.deliveryPaymentDetail.serviceCost).toLocaleString()} ₽`,
  };
});

const vat = computed(() => {
  return {
    title: 'НДС',
    value: `${fields.mode.totalCost.vat} ₽`,
  };
});
const additionalServices = () => {
  return fields.mode.calculatedAdditionalServices.map(
    (service: CalculatedAdditionalServicesInterface) => {
      return [
        {
          title: `${service.title}`,
          value: `${service.paymentDetail.netCost} ₽`,
        },
      ];
    },
  );
};
</script>

<template>
  <div v-for="(item, index) in parcelInformation" :key="index" class="description">
    <Description :title="item.title" :items="item.items" />
  </div>
  <div class="description">
    <Description :title="marketplace.title" :items="[]" />
  </div>
  <div class="description">
    <Description :items="[tariff]" />
  </div>
  <div v-if="vat.value" class="description">
    <Description :items="[vat]" />
  </div>
  <div v-for="(service, index) in additionalServices()" :key="index" class="description">
    <Description :items="service" />
  </div>
  <div class="description">
    <TotalPrice :price="Number(fields.mode.totalCost.total).toLocaleString()" />
  </div>
</template>

<style lang="scss" scoped>
.description {
  margin: 1rem 0;
}
</style>
