<template>
  <div class="sender">
    <ElementBasis>
      <CdekHeadline size="7">{{ t('sendRequest.senderIs') }}</CdekHeadline>
      <RadioButton v-model="fields.client.type" value="FIZ">
        <template #title>{{ t('sendRequest.senderIsPerson') }}</template>
      </RadioButton>
      <RadioButton v-model="fields.client.type" value="UR">
        <template #title>{{ t('sendRequest.senderIsBusiness') }}</template>
      </RadioButton>
    </ElementBasis>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { inject, toRef } from 'vue';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
import { CdekHeadline } from '@cdek-ui-kit/vue';
import RadioButton from '@/components/other/RadioButton.vue';
import ElementBasis from '@/components/other/ElementBasis.vue';
const { t } = useI18n();

const container = useInjectContainer();
const fields = container.get(FieldsDTO);
const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.client.type = null;
const clientTypeRef = toRef(fields.client, 'type');

validatorsBuilder.build(clientTypeRef);
</script>

<style lang="scss" scoped>
.sender {
  margin-bottom: 1rem;
}
</style>
