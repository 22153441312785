export class TextHighlightService {
  protected _element: HTMLDivElement;
  constructor(parentElementSelector: HTMLDivElement) {
    this._element = parentElementSelector;
  }

  protected _observer = (el: HTMLDivElement) => {
    return new MutationObserver((mutationRecords) => {
      mutationRecords.forEach((mutationRecord) => {
        if (mutationRecord.type !== 'childList') {
          return;
        }
        mutationRecord.addedNodes.forEach((node) => {
          if ((node as HTMLElement).tagName !== 'DIV') {
            return;
          }
          const inputValue = el.querySelector('input')?.value;
          const els = (node as HTMLDivElement).getElementsByClassName('green-dropdown-item');
          (els.length ? [...els] : [node as HTMLDivElement]).forEach((el) => {
            const content = el.querySelector('.green-dropdown-item__content') as HTMLDivElement;
            const regexp = new RegExp(`(?<search>${inputValue})`, 'ig');
            if (!content) {
              return;
            }
            content.innerHTML = content.innerHTML.replace(
              regexp,
              "<span class='highlight'>$<search></span>",
            );
          });
        });
      });
    });
  };

  public observe = () => {
    if (!this._element) {
      return;
    }
    const el = this._element.querySelector('.city-autocomplete__control');
    if (!el) {
      return;
    }
    this._observer(el as HTMLDivElement).observe(el, {
      childList: true,
      subtree: true,
    });
  };
}
