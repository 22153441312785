import AbstractComponentsResolver from '@/services/Components/AbstractComponentsResolver';
import { defineAsyncComponent } from 'vue';

export default class FinalComponentResolver extends AbstractComponentsResolver {
  protected _components() {
    return {
      final: [
        {
          key: 'standard',
          component: defineAsyncComponent(
            () => import('@/components/Final/Standard/FinalStandard.vue'),
          ),
        },
        {
          key: 'G2M',
          component: defineAsyncComponent(() => import('@/components/Final/G2M/G2MFinal.vue')),
        },
      ],
    };
  }

  protected _defaults() {
    return {
      final: {
        key: 'standard',
        props: {},
      },
    };
  }
}
