import AbstractComponentsResolver, {
  type ComponentType,
} from '@/services/Components/AbstractComponentsResolver';
import { defineAsyncComponent } from 'vue';

type Components = {
  route: ComponentType[];
  packages: ComponentType[];
  notFoundTariffs: ComponentType[];
};

export default class CalculateComponentsResolver extends AbstractComponentsResolver {
  protected _components(): Components {
    return {
      route: this._route(),
      packages: this._packages(),
      notFoundTariffs: this._notFoundTariffs(),
    };
  }

  protected _route(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/Calculate/RouteInput/Standard.vue'),
        ),
      },
      {
        key: 'standardWithPackagesSelect',
        component: defineAsyncComponent(
          () =>
            import(
              '@/components/Calculate/RouteInput/StandardWithPackagesSelect/StandardWithPackagesSelect.vue'
            ),
        ),
      },
    ];
  }

  protected _packages(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/Calculate/Packages/Standard.vue'),
        ),
      },
      {
        key: 'none',
        component: null,
      },
      {
        key: 'G2M',
        component: defineAsyncComponent(
          () => import('@/components/Calculate/Packages/G2M/G2M.vue'),
        ),
      },
      {
        key: 'packagesWithInsurance',
        component: defineAsyncComponent(
          () =>
            import(
              '@/components/Calculate/Packages/packagesWithInsurance/packagesWithInsurance.vue'
            ),
        ),
      },
    ];
  }

  protected _notFoundTariffs(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/Calculate/NotFoundTariffs/Standard.vue'),
        ),
      },
    ];
  }

  protected _defaults() {
    return {
      route: {
        key: 'standard',
        props: {},
      },
      packages: {
        key: 'standard',
        props: {},
      },
      notFoundTariffs: {
        key: 'standard',
        props: {},
      },
    };
  }
}
