<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import type { IDimensionItem } from '../types';
import infoCircle from '../icons/info-circle.svg?component';

const props = defineProps<{
  item: IDimensionItem;
  selectedItemId: number;
}>();
const { t } = useI18n();

const dimension = computed(() => {
  if (!props.item.dimension) {
    return '';
  }

  return `${[
    props.item.dimension.length || 0,
    props.item.dimension.width || 0,
    props.item.dimension.height || 0,
  ].join('x')} ${t('calculate.packages.withSelect.cm')}, ${t('calculate.packages.withSelect.upTo', [
    `${props.item.maxWeight || 0} ${t('calculate.packages.withSelect.kg')}`,
  ])}`;
});
</script>

<template>
  <div
    class="choice-of-dimensions-item"
    :class="{ 'choice-of-dimensions-item--selected': item.id === selectedItemId }"
  >
    <div class="choice-of-dimensions-item__image">
      <img :src="item.icon" :alt="item.name" class="choice-of-dimensions-item__icon" />
    </div>

    <div class="choice-of-dimensions-item__data">
      <p class="choice-of-dimensions-item__title">
        {{ item.name }}
      </p>
      <p class="choice-of-dimensions-item__description">{{ dimension }}</p>
    </div>
    <div
      v-tooltip.bottom="{
        html: true,
        content: item.description,
        popperClass: ['base-tooltip'],
      }"
      class="choice-of-dimensions-item__info"
    >
      <infoCircle />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@cdek-ui-kit/vue/scss';
.choice-of-dimensions-item {
  position: relative;
  box-sizing: border-box;
  background: $Surface_Neutral;
  border-radius: 10px;
  display: flex;
  padding: 16px 12px;
  width: 100%;
  cursor: pointer;

  &__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__data {
    margin-left: 64px;
  }

  &__title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 2px;
  }

  &__description {
    color: $Bottom_66;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: left;
  }

  &__info {
    position: absolute;
    top: 8px;
    right: 8px;

    :deep(svg) {
      &:hover {
        path {
          stroke-opacity: 1;
        }
      }
    }
  }

  &--selected {
    background: $Primary_10;
  }

  @include media-hover {
    background: $Primary_10;
  }
}
</style>
