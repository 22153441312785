<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { inject } from 'vue';
import CityAutocompleteService from '@/services/CityAutocomplete/CityAutocompleteService';
import type Container from 'mini-ioc';
import FieldsDTO from '@/services/FieldsDTO';
import CityAutocompleteV2 from '@/components/other/CityAutocompleteV2.vue';
import type { ITip } from '@/components/other/Tips/types';

interface ICalculateLTL {
  title: string;
  cityFromTips: ITip[];
  cityToTips: ITip[];
}
defineProps<ICalculateLTL>();

const { t } = useI18n();

const container = inject('container') as Container;
const fields = container.get(FieldsDTO);
const cityAutocompleteService = container.get(CityAutocompleteService);

fields.route = {
  cityFrom: {
    code: null,
    name: null,
    uuid: null,
  },
  cityTo: {
    code: null,
    name: null,
    uuid: null,
  },
};
</script>

<template>
  <div class="calculator-route">
    <div class="calculator-route__title">
      {{ title }}
    </div>
    <div class="calculator-route__inputs">
      <CityAutocompleteV2
        v-model="fields.route.cityFrom"
        :label="t('calculate.from')"
        :service="cityAutocompleteService.cityFrom.bind(cityAutocompleteService)"
        :city-tips="cityFromTips"
      />
      <CityAutocompleteV2
        v-model="fields.route.cityTo"
        :label="t('calculate.to')"
        :service="cityAutocompleteService.cityTo"
        :city-tips="cityToTips"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.calculator-route {
  &__title {
    @include headline-5;

    text-align: left;
    margin-bottom: 24px;

    @include media-min-lg {
      @include headline-4;
    }
  }

  &__inputs {
    display: flex;
    gap: 1rem;
    margin-bottom: 24px;

    @include media-sm {
      flex-wrap: wrap;
      gap: 24px;
    }

    :deep(.green-input__tip:empty) {
      display: none;
    }
  }
}
</style>
