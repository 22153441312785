import type GetServiceInterface from '@/services/GetService/GetServiceInterface';
import axios from 'axios';
import type TariffInterface from '@/interfaces/TariffInterface';
import { inject } from 'mini-ioc';
import WidgetProps from '@/services/WidgetProps';
import FieldsDTO from '@/services/FieldsDTO';

export default class GetService implements GetServiceInterface {
  public uri = '/api/v1/calculator/getService';

  public constructor(public widgetProps = inject(WidgetProps), public fields = inject(FieldsDTO)) {}

  public async get(tariff: TariffInterface, tariffEc4Id: string | number) {
    if (!tariff || !tariffEc4Id) {
      return Promise.reject();
    }
    const response = await axios.post(
      this.widgetProps.host + this.uri,
      this.getRequestBody(tariff, tariffEc4Id),
      {
        headers: {
          'X-User-Locale': this.widgetProps.language,
        },
      },
    );
    if (response.status === 200 && response.data.data.ec4Id) {
      return response.data.data;
    }
    throw new Error('bad request');
  }
  public getRequestBody(service: TariffInterface, tariffEc4Id: string | number) {
    return {
      sender: {
        cityId: this.fields.route.cityFrom?.uuid,
        contragentType: this.widgetProps.tariffs.sender.contragentType ?? 'FIZ',
      },
      receiver: {
        cityId: this.fields.route.cityTo?.uuid,
        contragentType: this.widgetProps.tariffs.receiver.contragentType ?? 'FIZ',
      },
      payer: {
        contractId: this.widgetProps.tariffs.payer.contractId ?? null,
        payerType: this.widgetProps.tariffs.payer.payerType ?? 'sender',
      },
      orderParam: {
        orderTypeCode: this.widgetProps.tariffs.orderParam.orderTypeCode ?? 1,
        additionalOrderTypeCodes:
          this.widgetProps.tariffs.orderParam.additionalOrderTypeCodes ?? [],
      },
      interfaceCode: this.widgetProps.tariffs.interfaceCode ?? 'ec5_front',
      currencyMark: this.widgetProps.currencyMark,
      packages: [
        {
          length: Number(this.fields.packages.length),
          width: Number(this.fields.packages.width),
          height: Number(this.fields.packages.height),
          weight: Number(this.fields.packages.weight),
        },
      ],
      filters: {
        calcServicesId: [service.uuid],
      },
      tariffTypeId: tariffEc4Id,
      language: this.widgetProps.language ?? 'eng',
    };
  }
}
