<script setup lang="ts">
import { computed } from 'vue';
import StepControllerService from '@/services/StepControllerService';
import useInjectContainer from '@/composables/useInjectContainer';

const container = useInjectContainer();
const stepController = container.get(StepControllerService);
type Crumb = {
  value: string;
  title: string;
  success?: boolean;
};
const props = defineProps<{
  payload: Array<Crumb> | [];
}>();

const data = computed(() => {
  const indexModelValue = props.payload.findIndex((el) => el.value === stepController.step);
  return props.payload.map((crumb) => {
    const indexCrumb = props.payload.findIndex((el) => el.value === crumb.value);
    return {
      ...crumb,
      success: indexCrumb < indexModelValue,
    };
  });
});

const select = (crumb: Crumb) => {
  if (!crumb.success) {
    return;
  }
  stepController.step = crumb.value;
};
</script>

<template>
  <div class="container">
    <div class="breadcrumbs">
      <button
        v-for="(crumb, index) in data"
        :key="index"
        :class="[
          'breadcrumb',
          {
            breadcrumb_success: crumb.success,
            breadcrumb_active: crumb.value === stepController.step,
          },
        ]"
        @click.prevent.stop="select(crumb)"
      >
        <svg
          v-if="index !== 0"
          width="12"
          height="28"
          viewBox="0 0 12 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0H2.65146C1.3227 0 0.363374 1.27181 0.728412 2.54944L4 14L0.728413 25.4506C0.363375 26.7282 1.3227 28 2.65146 28H12V0Z"
          />
        </svg>
        <div
          :class="[
            'breadcrumb__title',
            {
              breadcrumb__title_first: index == 0,
              breadcrumb__title_end: index == payload.length - 1,
            },
          ]"
        >
          {{ crumb.title }}
        </div>
        <svg
          v-if="index !== payload.length - 1"
          width="11"
          height="28"
          viewBox="0 0 11 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H5.4914C6.38436 0 7.16913 0.591955 7.41444 1.45056L11 14L7.41445 26.5494C7.16913 27.408 6.38436 28 5.4914 28H0V0Z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.container {
  width: 100%;
  height: 3rem;
  position: relative;
  overflow-y: visible;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.breadcrumbs {
  display: flex;
  width: max-content;
  position: absolute;
  .breadcrumb {
    display: flex;
    background: none;
    border: none;
    padding: 0;
    svg {
      fill: $Surface_Neutral;
    }
    &_active {
      svg {
        fill: $Tertiary;
      }
      .breadcrumb__title {
        color: $Peak;
        background-color: $Tertiary;
      }
    }
    &_success {
      cursor: pointer;
      svg {
        fill: $Primary_10;
      }
      .breadcrumb__title {
        color: $Primary_Active;
        background-color: $Primary_10;
      }
    }
    &__title {
      @include initials-avatar-1;
      width: fit-content;
      background-color: $Surface_Neutral;
      color: $Bottom_60;
      padding: 6px 0px 6px 0px;
      &_first {
        padding: 6px 0px 6px 10px;
        border-radius: 4px 0px 0px 4px;
      }
      &_end {
        padding: 6px 10px 6px 0px;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
}
</style>
