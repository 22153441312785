<script setup lang="ts">
import { CdekHeadline } from '@cdek-ui-kit/vue';
import ElementBasis from '../../other/ElementBasis.vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
  <div class="not-found">
    <ElementBasis>
      <div class="not-found__title">
        <CdekHeadline size="7">{{ t('calculate.tariffsNotFound.title') }}</CdekHeadline>
      </div>
      <div class="not-found__description">
        {{ t('calculate.tariffsNotFound.description') }}
      </div>
    </ElementBasis>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.not-found {
  width: 100%;
  .basis-element {
    background-color: $Surface_Error;
    margin: 1rem 0;
    gap: 0.5rem;
  }
  &__description {
    @include body-2;
  }
}
</style>
