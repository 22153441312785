import type SendRequestInterface from '@/services/sendRequest/SendRequestInterface';
import GoogleRecaptcha from '@/services/GoogleRecaptcha';
import axios from 'axios';
import StepControllerService from '@/services/StepControllerService';
import { inject } from 'mini-ioc';
import FieldsDTO from '@/services/FieldsDTO';
import WidgetProps from '@/services/WidgetProps';
import { analytics } from '@/services/analytics';

export default class SendRequestService implements SendRequestInterface {
  protected _captchaToken: string | undefined;
  public uri = '/api/v1/feedback';
  public isPending = false;
  public constructor(
    public fields = inject(FieldsDTO),
    public widgetProps = inject(WidgetProps),
    public captcha = inject(GoogleRecaptcha),
    public stepController = inject(StepControllerService),
  ) {}

  public send = async () => {
    if (this.isPending) return;

    this.isPending = true;
    this._captchaToken = await this.captcha.getToken();
    const response = await axios.post(this.widgetProps.host + this.uri, this.getRequestBody(), {});
    this.isPending = false;
    if (response.status === 200 && !response.data.errors) {
      analytics.onContactsSend();

      this.stepController.next('sendRequest');
      return;
    }
    throw new Error('bad request');
  };

  public getRequestBody = () => {
    const modeName = this.fields.tariff?.modeDetails.find(
      (mode) => mode.tariffEc4Id === this.fields?.mode?.ec4Id,
    )?.modeName;

    return {
      formid: 'calculator_vue3',
      tranid: '000',
      name: this.fields.client.name,
      email: this.fields.client.email,
      phone: this.fields.client.phone,
      clientType: this.fields.client.type,
      title: 'Новая заявка на расчет (калькулятор)',
      message: `
        Город-отправитель: ${this.fields.route.cityFrom.name},
        Город-получатель: ${this.fields.route.cityTo.name},
        Весогабариты: ${JSON.stringify(this.fields.packages)},
        Тариф: ${this.fields.tariff?.serviceName},
        Режим доставки: ${modeName},
        Примерная стоимость: ${this.fields.mode?.deliveryPaymentDetail.totalCost},
        Валюта: ${this.widgetProps.currencyMark},
        `,
      token: this._captchaToken,
    };
  };
}
