<template>
  <div class="agree-personal">
    <Checkbox v-model="fields.client.agree">
      <template #description>
        <div class="description">
          {{ t('sendRequest.iamAgreeWith') }}
          <CdekLink
            ><a :href="t('sendRequest.privacyPolicyUrl')" target="_blank" class="description">{{
              t('sendRequest.iamAgreeWithLink')
            }}</a></CdekLink
          >
        </div>
      </template>
    </Checkbox>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { inject, toRef } from 'vue';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
import Checkbox from '@/components/other/Checkbox.vue';
import { CdekLink } from '@cdek-ui-kit/vue';
const { t } = useI18n();

const container = useInjectContainer();
const fields = container.get(FieldsDTO);
const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.client.agree = false;
const agreeRef = toRef(fields.client, 'agree');
validatorsBuilder.build(agreeRef).truth();
</script>
