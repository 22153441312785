<script setup lang="ts">
import RadioButton from '@/components/other/RadioButton.vue';
import { ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps<{
  tariffUuid: string;
  tariffEc4Id: number;
  serviceName: string;
  deliveryPeriod: string | number;
  price: number;
  currencySymbol: string;
  selectedModeId: number | undefined | null;
}>();
const emits = defineEmits<{
  (e: 'selectServiceModeId', value: number): void;
}>();

const selectedService: Ref<number | undefined | null> = ref(props.selectedModeId);
</script>

<template>
  <RadioButton
    v-model="selectedService"
    :value="tariffEc4Id"
    @click="emits('selectServiceModeId', tariffEc4Id as number)"
  >
    <template #title>
      <div class="descripiton">
        <div class="descripiton__name">{{ serviceName }}</div>
        <div class="descripiton__duration">({{ deliveryPeriod }} {{ t('selectTariff.days') }})</div>
        <div class="descripiton__price">
          {{ Number(price).toLocaleString() || '' }} {{ currencySymbol }}
        </div>
      </div>
    </template>
  </RadioButton>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.descripiton {
  display: flex;
  width: 100%;
  gap: 1rem;
  @include media-xs {
    flex-wrap: wrap;
    gap: 0;
  }
  &__name {
    @include media-xs {
      flex-basis: 100%;
    }
  }
  &__duration {
    margin: 0 1rem 0 1rem;
    @include media-xs {
      margin: 0 0 0 0;
      flex-basis: 50%;
    }
  }
  &__price {
    margin-left: auto;
  }
}
</style>
