<script setup lang="ts">
import useInjectContainer from '@/composables/useInjectContainer';
import type { Component } from 'vue';
import TariffsComponentResolver from '@/services/Components/TariffsComponentResolver';
import ButtonSubmit from './ButtonSubmit.vue';
import SelectTariffService from '@/services/SelectTariff/SelectTariffService';
import { CdekForm } from '@cdek-ui-kit/vue';

const container = useInjectContainer();
const tariffs = container.get(TariffsComponentResolver).create();
const selectTariffService = container.get(SelectTariffService);
</script>

<template>
  <CdekForm @submit="selectTariffService.submit">
    <component
      :is="tariffs.tariffs.component as Component"
      v-if="tariffs.tariffs.component"
      :payload="tariffs.tariffs.payload"
    />
    <component
      :is="tariffs.submitButton.component as Component"
      v-if="tariffs.submitButton?.component"
      v-bind="tariffs.submitButton.payload"
    />
  </CdekForm>
</template>
