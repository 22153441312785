<script setup lang="ts">
import ParcelSizeItem from '@/components/other/ParcelSizeItem.vue';
import { useI18n } from 'vue-i18n';
import { computed, inject, toRefs } from 'vue';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';

const { t } = useI18n();
const container = useInjectContainer();
const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

defineProps<{
  readonly?: {
    width?: boolean;
    height?: boolean;
    length?: boolean;
    weight?: boolean;
  };
}>();
const fields = container.get(FieldsDTO);

const packagesRefs = toRefs(fields.packages);

const lengthValidator = validatorsBuilder.build(packagesRefs.length).number().numberNotNull();
const widthValidator = validatorsBuilder.build(packagesRefs.width).number().numberNotNull();
const heightValidator = validatorsBuilder.build(packagesRefs.height).number().numberNotNull();
const weightValidator = validatorsBuilder.build(packagesRefs.weight).number().numberNotNull();

heightValidator.custom(
  computed(() => {
    return !(fields.other.packType === 'pallet' && Number(fields.packages.height) >= 180);
  }),
  'Не более 180 см',
);
</script>

<template>
  <div class="parcel-size">
    <ParcelSizeItem
      v-model="fields.packages.length"
      :label="t('calculate.packages.length')"
      :is-valid="lengthValidator.validated.value"
      :readonly="readonly?.length"
    />
    <ParcelSizeItem
      v-model="fields.packages.width"
      :label="t('calculate.packages.width')"
      :is-valid="widthValidator.validated.value"
      :readonly="readonly?.width"
    />
    <ParcelSizeItem
      v-model="fields.packages.height"
      :label="t('calculate.packages.height')"
      :is-valid="heightValidator.validated.value"
      :readonly="readonly?.height"
    />
    <ParcelSizeItem
      v-model="fields.packages.weight"
      :label="t('calculate.packages.weight')"
      :is-valid="weightValidator.validated.value"
      :readonly="readonly?.weight"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.parcel-size {
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
</style>
