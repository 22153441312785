<script setup lang="ts">
import { CdekHeadline } from '@cdek-ui-kit/vue';
import ElementBasis from '../../other/ElementBasis.vue';
type Data = {
  title: string;
  value: string;
};

const props = defineProps<{
  title?: string;
  items: Array<Data>;
  class?: string;
}>();
</script>

<template>
  <ElementBasis>
    <div class="description">
      <div v-if="title" class="description__title">
        <CdekHeadline size="6">{{ title }}</CdekHeadline>
      </div>
      <div v-if="items?.length" class="desciption__items">
        <div v-for="(item, index) in items" :key="index" class="item">
          <div :class="['item__title', props.class]">
            {{ item.title }}
          </div>
          <div :class="['item__value', props.class]">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
  </ElementBasis>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.item {
  @include body-2;
  display: flex;
  width: 100%;
  &__value {
    flex-shrink: 0;
    margin-left: auto;
  }
}
.totalPrice {
  @include headline-5;
}
</style>
