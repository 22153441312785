import type CityAutocompleteInterface from '@/services/CityAutocomplete/CityAutocompleteInterface';
import type { CityResponseInterface } from '@/interfaces/CityResponseInterface';

export default abstract class CityAutocompleteDecorator implements CityAutocompleteInterface {
  public constructor(protected _core: CityAutocompleteInterface) {}

  public get widgetProps() {
    return this._core.widgetProps;
  }
  public get uri() {
    return this._core.uri;
  }

  public getCity(query: string): Promise<any> {
    return this._core.getCity.call(this, query);
  }
  public cityFrom(query: string): Promise<any> {
    return this._core.cityFrom.call(this, query);
  }
  public cityTo = (query: string): Promise<any> => {
    return this._core.cityTo.call(this, query);
  };

  public request(query: string): Promise<any> {
    return this._core.request.call(this, query);
  }

  public response(response: CityResponseInterface): Record<string, any> {
    return this._core.response.call(this, response);
  }
}
