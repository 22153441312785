<script setup lang="ts">
import { CdekInput } from '@cdek-ui-kit/vue';
import { vMaska } from 'maska';

const props = defineProps<{
  label: string;
  class?: string;
  validRes: string | boolean;
  modelValue: any;
  readonly?: boolean;
  required?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();

const onFocus = (el: FocusEvent): void => {
  if ((el.target as HTMLInputElement).value) {
    return;
  }
  el.target?.addEventListener('input', () => {
    if ((el.target as HTMLInputElement).value !== '.00') {
      return;
    }
    (el.target as HTMLInputElement).setSelectionRange(0, 0);
  });
};
const onBlur = (): void => {
  if (props.modelValue !== '.00') {
    return;
  }
  emit('update:modelValue', null);
};

const update = (data: string) => {
  emit('update:modelValue', data);
};
</script>

<template>
  <CdekInput
    v-maska
    :label="label"
    :valid-res="validRes"
    :required="required"
    :class="props.class"
    :model-value="(modelValue as string)"
    data-maska="ZZZ.##"
    data-maska-tokens="Z:[0-9]:optional"
    :readonly="readonly"
    @update:model-value="update"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<style lang="scss" scoped></style>
