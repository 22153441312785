<script setup lang="ts">
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
import { inject, toRefs } from 'vue';
import ParcelSizeItem from '@/components/other/ParcelSizeItem.vue';
import { useI18n } from 'vue-i18n';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import { CdekFormControl } from '@cdek-ui-kit/vue';

const container = useInjectContainer();

const { t } = useI18n();

const fields = container.get(FieldsDTO);

const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.packages = {
  width: null,
  height: null,
  length: null,
  weight: null,
};

fields.other = {
  declaredCost: null,
};

const packagesRefs = toRefs(fields.packages);

const lengthValidator = validatorsBuilder.build(packagesRefs.length).number().numberNotNull();
const widthValidator = validatorsBuilder.build(packagesRefs.width).number().numberNotNull();
const heightValidator = validatorsBuilder.build(packagesRefs.height).number().numberNotNull();
const weightValidator = validatorsBuilder.build(packagesRefs.weight).number().numberNotNull();
const rules = {
  required: true,
  regex: /^[1-9][0-9]*$/,
};

const changeDeclaredCost = (value: Event) => {
  fields.other.declaredCost = (value.target as HTMLInputElement).value;
};
</script>

<template>
  <div class="packages__with_insurance">
    <ParcelSizeItem
      v-model="fields.packages.length"
      :label="t('calculate.packages.length')"
      :is-valid="lengthValidator.validated.value"
      required
    />
    <ParcelSizeItem
      v-model="fields.packages.width"
      :label="t('calculate.packages.width')"
      :is-valid="widthValidator.validated.value"
      required
    />
    <ParcelSizeItem
      v-model="fields.packages.height"
      :label="t('calculate.packages.height')"
      :is-valid="heightValidator.validated.value"
      required
    />
    <ParcelSizeItem
      v-model="fields.packages.weight"
      :label="t('calculate.packages.weight')"
      :is-valid="weightValidator.validated.value"
      required
    />

    <div class="parcel-size__insurance">
      <cdek-form-control
        name="declaredCost"
        label="Ценность посылки"
        :rules="rules"
        @input="changeDeclaredCost"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.packages__with_insurance {
  display: grid;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;

  @include media-min-lg {
    max-width: 944px;
    grid-template-columns: repeat(5, 1fr);
  }

  :deep(.parcel-size__item) {
    width: auto;

    .green-input__tip:empty {
      display: none;
    }
  }

  &__insurance {
    grid-column: 2 span;

    @include media-min-lg {
      grid-column: 5 / 6;
    }

    :deep(.green-input) {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
</style>
