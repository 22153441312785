<script setup lang="ts">
import { provide } from 'vue';
import { CdekForm } from '@cdek-ui-kit/vue';

import useInjectContainer from '@/composables/useInjectContainer';
import SendRequestComponentResolver from '@/services/Components/SendRequestComponentResolver';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import FieldsDTO from '@/services/FieldsDTO';
import { analytics } from '@/services/analytics';
import useRemoveNull from '@/composables/useRemoveNull';
import ButtonSubmit from '../ButtonSubmit.vue';
import SendRequestService from '@/services/SendRequest/SendRequestService';
import Headline from './Headline.vue';
import Description from './Description.vue';

const container = useInjectContainer();
const sendRequestService = container.get(SendRequestService);
const validatorsBuilder = new ValidatorsBuilder();
const fields = container.get(FieldsDTO);

provide('validatorsBuilder', validatorsBuilder);

const sendRequestComponents = container.get(SendRequestComponentResolver).create();

const submit = () => {
  useRemoveNull(fields.client);
  if (!validatorsBuilder.isValid) {
    return;
  }
  sendRequestService.send();
};

const change = () => {
  if (validatorsBuilder.isValid) {
    analytics.onContactsFill();
  }
};
</script>

<template>
  <CdekForm @submit="submit" @change="change">
    <Headline />
    <Description />
    <component
      :is="field.component"
      v-for="(field, index) in sendRequestComponents.fields"
      :key="index"
    />
    <ButtonSubmit />
  </CdekForm>
</template>
