import type GetServiceInterface from '@/services/GetService/GetServiceInterface';
import type TariffInterface from '@/interfaces/TariffInterface';

export default abstract class GetServiceDecorator implements GetServiceInterface {
  protected _core: GetServiceInterface;
  public constructor(core: GetServiceInterface) {
    this._core = core;
  }
  public get fields() {
    return this._core.fields;
  }
  public get widgetProps() {
    return this._core.widgetProps;
  }
  public get uri() {
    return this._core.uri;
  }
  public get(tariff: TariffInterface, tariffModeId: string | number) {
    return this._core.get.call(this, tariff, tariffModeId);
  }

  public getRequestBody(
    service: TariffInterface,
    tariffModeId: string | number,
  ): Record<string, any> {
    return this._core.getRequestBody.call(this, service, tariffModeId);
  }
}
