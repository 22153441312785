import AbstractConfigurator from '@/services/AbstractConfigurator';
import SendRequestProxyToAmo from '@/decorators/SendRequest/SendRequestProxyToAmo';

export default class SendRequestConfigurator extends AbstractConfigurator {
  public decorators = [
    {
      name: 'proxyToAmo',
      class: SendRequestProxyToAmo,
    },
  ];
}
