<template>
  <CdekInput
    v-model="fields.client.email"
    :label="t('sendRequest.email')"
    :valid-res="emailValidator.validated.value"
  />
</template>

<script setup lang="ts">
import { CdekInput } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
import { inject, toRef } from 'vue';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
const { t } = useI18n();

const container = useInjectContainer();
const fields = container.get(FieldsDTO);
const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.client.email = null;

const emailRef = toRef(fields.client, 'email');

const emailValidator = validatorsBuilder.build(emailRef).email();
</script>
