<script setup lang="ts"></script>

<template>
  <div class="calculator__line"></div>
</template>

<style lang="scss">
.calculator__line {
  width: 100%;
  height: 3px;
  border-bottom: dashed rgba(0, 0, 0, 0.2);
  margin: 1rem 0 1.5rem 0;
}
</style>
