<template>
  <div class="tips">
    <div v-for="(tip, index) in cityTips" :key="index" @click="onClickSelectTip(tip)">
      {{ tip.shortName }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ITip, ITipsProps } from '@/components/other/Tips/types';

const emit = defineEmits<{
  (e: 'selectTip', tip: ITip): void;
}>();

defineProps<ITipsProps>();

const onClickSelectTip = (cityTip: ITip) => {
  emit('selectTip', cityTip);
};
</script>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.tips {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  > div {
    color: $Bottom_60;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
    cursor: pointer;

    @include media-min-lg {
      font-size: 12px;
      line-height: 18px;
    }
  }

  @include media-min-lg {
    margin-top: 6px;
  }
}
</style>
