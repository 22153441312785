import { ref } from 'vue';

export default class StepControllerService {
  protected _step = ref('calculate');
  protected _allSteps: string[];

  public constructor(allSteps: string[]) {
    this._allSteps = this._sortSteps(allSteps);
  }

  public get step() {
    return this._step.value;
  }
  public set step(step: string) {
    this._step.value = step;
  }
  public get steps() {
    return this._allSteps;
  }
  public next = (currentStep: string) => {
    const currentStepIndex = this._allSteps.findIndex((step) => step === currentStep);
    this._step.value = this._allSteps[currentStepIndex + 1] ?? this._allSteps[0];
  };
  public prev = () => {
    const currentStepIndex = this._allSteps.findIndex((step) => step === this._step.value);
    this._step.value = this._allSteps[currentStepIndex - 1] ?? this._allSteps[0];
  };
  public reset = () => {
    this._step.value = this._allSteps[0];
  };

  private _sortSteps = (steps: string[]) => {
    const sortSteps = [];
    if (steps.includes('calculate')) {
      sortSteps.push('calculate');
    }

    if (steps.includes('selectTariff')) {
      sortSteps.push('selectTariff');
    }

    if (steps.includes('sendRequest')) {
      sortSteps.push('sendRequest');
    }

    if (steps.includes('final')) {
      sortSteps.push('final');
    }

    return sortSteps;
  };
}
