<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean | undefined;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
</script>

<template>
  <div class="green-checkbox">
    <label class="green-checkbox__container">
      <input
        type="checkbox"
        :checked="modelValue"
        @change="emit('update:modelValue', !props.modelValue)"
      />
      <div class="green-checkbox__checkmark"></div>
      <slot name="description"></slot>
    </label>
    <slot name="control"></slot>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';

.green-checkbox {
  display: flex;
  gap: 1rem;
  width: 100%;
  @include media-xs {
    flex-wrap: wrap;
    gap: 0;
  }
}
.green-checkbox__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.green-checkbox__container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.green-checkbox__checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: rgba($Primary, 0.1);
  border: 1px $Primary solid;
  border-radius: 6px;
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
}

.green-checkbox__container input:checked ~ .green-checkbox__checkmark {
  background-color: $Primary;
}

.green-checkbox__checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.green-checkbox__container input:checked ~ .green-checkbox__checkmark:after {
  display: block;
}

.green-checkbox__container .green-checkbox__checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-radius: 2px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: content-box;
}
</style>
