import './assets/main.scss';
import { createApp } from 'vue';
import type { App as VueApp } from 'vue';
import App from '@/App.vue';
import AppInitService from '@/services/AppInit/AppInitService';
import Container from 'mini-ioc';
import ServiceProvider from '@/services/ServiceProvider';
import '@cdek-ui-kit/vue/style.css';
import FloatingVue from 'floating-vue';
import cloneDeep from 'lodash/cloneDeep';
import * as Sentry from '@sentry/vue';

declare global {
  interface Window {
    WidgetCalc?: any;
    grecaptcha: {
      execute: CallableFunction;
    };
    sentryInitialized?: boolean;
  }
}

window.WidgetCalc ?? (window.WidgetCalc = {});

let vueApps: Record<string, VueApp<Element>> = {};

const createCalculatorApp = (id = '#widget-calc', WidgetCalc = cloneDeep(window.WidgetCalc)) => {
  const container = new Container();
  new ServiceProvider(container, WidgetCalc.configs?.components, WidgetCalc.configs).boot();
  const app = container.get(AppInitService).init(WidgetCalc.configs);

  const vueApp = createApp(App, WidgetCalc.configs);

  if (!window.sentryInitialized) {
    Sentry.init({
      app: vueApp,
      dsn: 'https://f8ef87ea628f25028eab5706f42dbee3@sentry-tech.cdek.ru/280',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    window.sentryInitialized = true;
  }

  // prettier-ignore
  vueApp
    .use(app.i18n)
    .use(FloatingVue)
    .provide('container', container)
    .provide('WidgetCalc', WidgetCalc)
    .mount(id);

  vueApps[id] = vueApp;

  return vueApp;
};

if (!window.WidgetCalc.configs?.manualRun) {
  createCalculatorApp();
}

window.WidgetCalc.renderCalculator = createCalculatorApp;

window.WidgetCalc.unmountApp = (id?: string) => {
  if (!id) {
    Object.values(vueApps).forEach((app) => app.unmount());
    vueApps = {};
  } else {
    vueApps[id]?.unmount();
    delete vueApps[id];
  }
};
