<script setup lang="ts">
import { reactive } from 'vue';
import { CdekInput } from '@cdek-ui-kit/vue';
import { vMaska } from 'maska';

defineProps<{
  modelValue: string | undefined;
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const update = (value: string) => {
  const newValue = value.replaceAll(/\s/g, '') || '1';
  emits('update:modelValue', Number(newValue) ? newValue : '1');
};

const options = reactive({
  mask: 'FFFFFFF',
  tokens: {
    F: { pattern: /[0-9]|\s/ },
  },
});
</script>

<template>
  <CdekInput
    v-maska:[options]
    placeholder="₽"
    :model-value="Number(modelValue?.replaceAll(/\s/g, '')).toLocaleString()"
    small
    class="insurance-control"
    @update:model-value="update"
  >
  </CdekInput>
</template>

<style lang="scss">
.insurance-control {
  .green-input {
    font-size: 0.5rem;
    &__control {
      width: 5rem;
      border-radius: 4px;
      padding-inline: 6px;
    }
    &__control_small {
      height: 24px !important;
    }
    &__input {
      font-size: 0.8rem;
      text-align: center;
    }
    &__tip {
      display: none;
    }
  }
}
</style>
