import type SelectTariffInterface from '@/services/SelectTariff/SelectTariffInterface';
import type { ServiceListInterface } from '@/interfaces/GetServicesInterface';
import type GetServiceInterface from '@/services/GetService/GetServiceInterface';
import type StepControllerService from '@/services/StepControllerService';

export default abstract class SelectTariffDecorator implements SelectTariffInterface {
  protected _core: SelectTariffInterface;

  public constructor(core: SelectTariffInterface) {
    this._core = core;
  }

  public get fields() {
    return this._core.fields;
  }
  public selectService(id: number, tariff: ServiceListInterface) {
    this._core.selectService(id, tariff);
  }
  public get getService(): GetServiceInterface {
    return this._core.getService;
  }
  public get stepController(): StepControllerService {
    return this._core.stepController;
  }
  public submit() {
    return this._core.submit();
  }
}
