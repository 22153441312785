<script setup lang="ts">
import { type Component, inject, provide, ref, watch } from 'vue';
import type Container from 'mini-ioc';
import useRemoveNull from '@/composables/useRemoveNull';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import CalculateService from '@/services/Calculate/CalculateService';
import FieldsDTO from '@/services/FieldsDTO';
import CalculateComponentsResolver from '@/services/Components/CalculateComponentsResolver';
import { CdekForm } from '@cdek-ui-kit/vue';
import ButtonSubmit from '@/components/Templates/Calculate/ButtonSubmit.vue';
import StepControllerService from '@/services/StepControllerService';

const container = inject('container') as Container;
const WidgetCalc: any = inject('WidgetCalc');

const fields = container.get(FieldsDTO);
const components = container.get(CalculateComponentsResolver).create();

const isStandardWithPackagesSelectRoute =
  WidgetCalc?.configs?.components?.calculate?.route?.key === 'standardWithPackagesSelect';

const validatorsBuilder = new ValidatorsBuilder();
provide('validatorsBuilder', validatorsBuilder);
const calculateService = container.get(CalculateService);
const stepController = container.get(StepControllerService);

const loading = ref(false);

watch(
  () => calculateService,
  () => {
    if (calculateService.notFoundTariffs) {
      loading.value = false;
    }
  },
  { deep: true },
);

watch(
  () => stepController,
  () => {
    loading.value = false;
  },
  { deep: true },
);

const submit = () => {
  loading.value = true;

  useRemoveNull(fields.route.cityTo);
  useRemoveNull(fields.route.cityFrom);
  useRemoveNull(fields.packages);

  if (validatorsBuilder.isValid) {
    calculateService.calculate();
    return;
  }

  loading.value = false;
};
</script>

<template>
  <CdekForm autocomplete="off" @submit="submit">
    <div class="calculate">
      <component
        :is="components.route.component as Component"
        v-if="components.route.component"
        v-bind="components.route.payload"
      />
      <component
        :is="components.packages.component as Component"
        v-if="components.packages.component"
        :payload="components.packages.payload"
      />
      <ButtonSubmit v-if="!isStandardWithPackagesSelectRoute" :loading="loading" />
      <component
        :is="components.notFoundTariffs.component as Component"
        v-if="components.notFoundTariffs.component && calculateService.notFoundTariffs"
        :payload="components.notFoundTariffs.payload"
      />
    </div>
  </CdekForm>
</template>
