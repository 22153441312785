<script setup lang="ts">
import { computed, inject, onMounted, ref, toRefs } from 'vue';
import { CdekDropdownBox, CdekButton, CdekInput } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
import ChoiceOfDimensionsItem from './components/ChoiceOfDimensionsItem.vue';
import type { IDimensionItem } from './types';
import DropdownTrigger from './components/DropdownTrigger.vue';
import type ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import FieldsDTO from '@/services/FieldsDTO';
import type Container from 'mini-ioc';

const props = defineProps<{
  value: IDimensionItem | null;
  placeholder: string;
  options: Array<IDimensionItem> | null;
  hasError: boolean;
}>();

const container = inject('container') as Container;
const fields = container.get(FieldsDTO);

const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.packages = {
  width: null,
  height: null,
  length: null,
  weight: null,
};

const packageLimits = {
  length: 43,
  width: 23,
  height: 50,
  weight: 5,
};

const packagesRefs = toRefs(fields.packages);

const lengthValidator = validatorsBuilder.build(packagesRefs.length).number().numberNotNull();
const widthValidator = validatorsBuilder.build(packagesRefs.width).number().numberNotNull();
const heightValidator = validatorsBuilder.build(packagesRefs.height).number().numberNotNull();
const weightValidator = validatorsBuilder.build(packagesRefs.weight).number().numberNotNull();

lengthValidator.custom(
  computed(() => {
    return Number(fields.packages.length) <= packageLimits.length;
  }),
  `Не более ${packageLimits.length} см`,
);
widthValidator.custom(
  computed(() => {
    return Number(fields.packages.width) <= packageLimits.width;
  }),
  `Не более ${packageLimits.width} см`,
);
heightValidator.custom(
  computed(() => {
    return Number(fields.packages.height) <= packageLimits.height;
  }),
  `Не более ${packageLimits.height} см`,
);
weightValidator.custom(
  computed(() => {
    return Number(fields.packages.weight) <= packageLimits.weight;
  }),
  `Не более ${packageLimits.weight} кг`,
);

enum Tabs {
  justAbout = 'JustAbout',
  exactly = 'Exactly',
}

const minDimensionValue = 1;
const maxDimensionValue = 1500;
const minWeightValue = 0.001;
const maxWeightValue = 10000;

const opened = ref(false);
const selectedTab = ref(Tabs.justAbout);
const baseDropdownMenu = ref<typeof CdekDropdownBox | null>(null);

const toggleDropdown = () => {
  opened.value = !opened.value;
};

const clearForm = () => {
  fields.packages = {
    length: '',
    width: '',
    height: '',
    weight: '',
  };
};

const onSelectTab = (name: Tabs) => {
  selectedTab.value = name;
};

const { t } = useI18n();

const getDimension = (item: IDimensionItem) => {
  const { dimension } = item;

  if (!dimension) {
    return '';
  }

  return `${[dimension.length || 0, dimension.width || 0, dimension.height || 0].join('x')} ${t(
    'cm',
  )}, ${t('calculate.packages.withSelect.upTo', [
    `${item.maxWeight || 0} ${t('calculate.packages.withSelect.kg')}`,
  ])}`;
};

const close = () => {
  opened.value = false;
};

const isEmptyForm = () => {
  return (
    +fields.packages.weight === 0 &&
    +fields.packages.length === 0 &&
    +fields.packages.width === 0 &&
    +fields.packages.height === 0
  );
};

const query = computed(() => {
  if (isEmptyForm()) {
    return opened.value ? t('calculate.packages.withSelect.empty') : '';
  }

  const value = {
    dimension: {
      length: parseFloat(fields.packages.length),
      width: parseFloat(fields.packages.width),
      height: parseFloat(fields.packages.height),
    },
    maxWeight: parseFloat(fields.packages.weight),
  };

  return getDimension(value);
});

const select = (value: IDimensionItem) => {
  fields.packages = { ...value.dimension };
  fields.packages.weight = value.maxWeight;
  close();
};

const closeOur = () => {
  if (opened.value) {
    close();
  }
};

const setForm = (val: IDimensionItem) => {
  if (val && !val.id) {
    fields.packages = {
      width: `${val.dimension.width}`,
      height: `${val.dimension.height}`,
      length: `${val.dimension.length}`,
      weight: `${val.maxWeight}`,
    };

    return;
  }

  if (val && val.id) {
    clearForm();
  }
};

onMounted(() => {
  if (props.value) {
    setForm(props.value);
  }
});

const errorMessage = computed(() => {
  return props.hasError ? t('calculate.packages.withSelect.requiredField') : '';
});

const buttonText = computed(() => {
  return t('calculate.packages.withSelect.confirm') ?? t('calculate.packages.withSelect.select');
});
</script>

<template>
  <div
    v-cdek-click-outside="{
      handler: closeOur,
    }"
    style="position: relative"
  >
    <dropdown-trigger
      v-test-id="'direction-dimension'"
      :open="opened"
      :label="placeholder"
      :value="query"
      :valid-res="errorMessage"
      hide-tip-till-needed
      @click="toggleDropdown"
    />
    <cdek-dropdown-box v-if="opened" ref="baseDropdownMenu" class="choice-dropdown-menu">
      <div class="choice-tabs">
        <button
          v-test-id="'direction-dimension-tab-about'"
          class="choice-tabs__action"
          :class="{
            'choice-tabs__action--active': selectedTab === Tabs.justAbout,
          }"
          type="button"
          @click="onSelectTab(Tabs.justAbout)"
        >
          {{ t('calculate.packages.withSelect.approximateDimensions') }}
        </button>
        <button
          v-test-id="'direction-dimension-tab-exactly'"
          class="choice-tabs__action"
          :class="{
            'choice-tabs__action--active': selectedTab === Tabs.exactly,
          }"
          type="button"
          @click="onSelectTab(Tabs.exactly)"
        >
          {{ t('calculate.packages.withSelect.exactDimensions') }}
        </button>
      </div>
      <div class="choice-tabs-panel">
        <template v-if="selectedTab === Tabs.justAbout">
          <choice-of-dimensions-item
            v-for="(item, index) in options"
            :key="item.id"
            v-test-id="`direction-dimension-item-${index + 1}`"
            class="choice-item"
            :item="item"
            :selected-item-id="(value && value.id) || -1"
            @click="select(item)"
          />
        </template>
        <div v-else class="choice-form">
          <p class="choice-form__label">{{ t('calculate.packages.withSelect.length') }}</p>
          <cdek-input
            v-model="fields.packages.length"
            v-test-id="'direction-dimension-length'"
            decimal
            type="number"
            :min="`${minDimensionValue}`"
            small
            hide-tip-till-needed
            :placeholder="t('calculate.packages.withSelect.cm')"
            inputmode="numeric"
            :valid-res="lengthValidator.validated.value"
            :max="`${maxDimensionValue}`"
            class="choice-form__input"
            @clear="fields.packages.length = ''"
          >
          </cdek-input>
          <p class="choice-form__label">{{ t('calculate.packages.withSelect.width') }}</p>
          <cdek-input
            v-model="fields.packages.width"
            v-test-id="'direction-dimension-width'"
            decimal
            type="number"
            :min="`${minDimensionValue}`"
            small
            hide-tip-till-needed
            :placeholder="t('calculate.packages.withSelect.cm')"
            inputmode="numeric"
            :valid-res="widthValidator.validated.value"
            :max="`${maxDimensionValue}`"
            class="choice-form__input"
            @clear="fields.packages.width = ''"
          >
          </cdek-input>
          <p class="choice-form__label">{{ t('calculate.packages.withSelect.height') }}</p>
          <cdek-input
            v-model="fields.packages.height"
            v-test-id="'direction-dimension-height'"
            decimal
            type="number"
            :min="`${minDimensionValue}`"
            small
            hide-tip-till-needed
            :placeholder="t('calculate.packages.withSelect.cm')"
            inputmode="numeric"
            :valid-res="heightValidator.validated.value"
            :max="`${maxDimensionValue}`"
            class="choice-form__input"
            @clear="fields.packages.height = ''"
          >
          </cdek-input>
          <p class="choice-form__label">{{ t('calculate.packages.withSelect.weight') }}</p>
          <cdek-input
            v-model="fields.packages.weight"
            v-test-id="'direction-dimension-weight'"
            type="number"
            inputmode="numeric"
            :valid-res="weightValidator.validated.value"
            small
            step="0.1"
            :min="`${minWeightValue}`"
            :max="`${maxWeightValue}`"
            :placeholder="t('calculate.packages.withSelect.kg')"
            class="choice-form__input"
            @clear="fields.packages.weight = ''"
          >
          </cdek-input>
        </div>
        <cdek-button
          v-if="selectedTab !== Tabs.justAbout"
          v-test-id="'direction-dimension-btn'"
          class="choice-action"
          primary
          small
          @click.prevent="close"
        >
          {{ buttonText }}
        </cdek-button>
      </div>
    </cdek-dropdown-box>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
$desktop: 1000px;
$tabs-height: 42px;
$dropdown-height: 350px;

@mixin hide-scrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.base-dropdown-menu {
  &--shake-x {
    animation-name: shake-x;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
}

.choice-dropdown-menu {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px 0 #0000000a;
  filter: none;
  padding: 8px 0;
}

.choice-tabs {
  display: flex;
  margin: 0 8px;
  padding: 4px;
  background: rgba(51, 85, 104, 0.05);
  box-shadow: inset 0 1px 2px rgba(0, 33, 52, 0.05);
  border-radius: 10px;

  .choice-title + & {
    margin-top: 24px;

    @media (min-width: $desktop) {
      margin-top: 16px;
    }
  }

  &__action {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.55px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    flex: 1 1 auto;
    padding: 2px 8px;

    &--active {
      background: #fff;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.01),
        0 3px 1px rgba(0, 0, 0, 0.03);
      color: #158e3a;
      border-radius: 8px;
    }
  }
}

.choice-tabs-panel {
  height: 100%;
  max-height: 280px;
  overflow-y: auto;
  padding: 16px 8px 0;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $Tertiary_20;
  }

  .choice-tabs + & {
    padding-top: 0;
    margin-top: 24px;

    @media (min-width: $desktop) {
      margin-top: 16px;
    }
  }
}

.choice-item {
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.choice-form {
  display: grid;
  grid-template-columns: 2fr 4fr;
  align-items: flex-start;
  row-gap: 12px;
  padding-top: 4px;

  @include hide-scrollbar;

  &__input {
    padding: 0;

    :deep(input) {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      &[type='number'] {
        -moz-appearance: textfield; /* Firefox */
      }
    }

    :deep(.green-input__tip) {
      min-height: 0;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
  }
}

.choice-action {
  padding: 12px 16px;
  margin-top: 24px;

  &__btn {
    padding: 6px 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #fff;
  }
}

@keyframes shake-x {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
</style>
