<script setup lang="ts">
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
import type Container from 'mini-ioc';

const container = useInjectContainer() as Container;
const fields = container.get(FieldsDTO);
</script>

<template>
  <div class="calculator__route">
    {{ fields.route.cityFrom?.name || '' }} – {{ fields.route.cityTo?.name || '' }}
  </div>
</template>

<style lang="scss">
@import '@cdek-ui-kit/vue/scss';
.calculator__route {
  @include body-1;
}
</style>
