import CityAutocompleteDecorator from '@/services/CityAutocomplete/CityAutocompleteDecorator';
import axios from 'axios';

export default class RejectCrimea extends CityAutocompleteDecorator {
  public async request(query: string) {
    return axios.get(this._core.widgetProps.host + this._core.uri, {
      headers: {
        'X-User-Lang': this._core.widgetProps.language,
        'X-Reject-Crimea': true,
      },
      params: {
        query: query,
        limit: 5,
      },
    });
  }
}
