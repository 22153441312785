import AbstractConfigurator from '../AbstractConfigurator';
import RejectCrimea from '@/decorators/CityAutocomplete/RejectCrimea';

export default class CityAutocompleteConfigurator extends AbstractConfigurator {
  public decorators = [
    {
      name: 'rejectCrimea',
      class: RejectCrimea,
    },
  ];
}
