<script setup lang="ts">
import { ref, type Ref } from 'vue';
import RadioButton from '../../other/RadioButton.vue';
import { CdekBadge } from '@cdek-ui-kit/vue';
import Tooltip from '../../other/Tooltip.vue';

defineProps<{
  tariffEc4Id: number;
  serviceName: string;
  modeName: string;
  deliveryPeriod: string | number;
  price: number;
  currencySymbol: string;
  deliveryMode: { code: number; entrypoint: string; endpoint: string };
}>();
const emits = defineEmits<{
  (e: 'selectServiceModeId', value: number): void;
}>();

const selectedService: Ref<number | undefined> = ref();

const tooltipText = `Для забора груза более 30 кг вы можете воспользоваться услугой “погрузка на адресе”. <br>
Стоимость: до 100 кг -250р, до 200 кг - 500р. (Или вы можете погрузить товар самостоятельно). <br>
Сложные погрузки (нет грузового лифта, пронос более 100м в 1 сторону, выше 2 этажа). <br>
Стоимость: X2 к тарифу`;
</script>

<template>
  <RadioButton
    v-model="selectedService"
    :value="tariffEc4Id"
    class="tariff"
    @click="emits('selectServiceModeId', selectedService as number)"
  >
    <template #title>
      <div class="service">
        <div class="service__title">{{ serviceName }} {{ modeName }}</div>
        <div class="service__period">({{ deliveryPeriod }} рабочих дней)</div>
        <div class="service__description">
          <Tooltip
            v-if="deliveryMode.entrypoint == 'door' && deliveryMode.endpoint == 'door'"
            :text="tooltipText"
            :html="tooltipText"
            class="tooltip"
          />
          <CdekBadge
            v-if="deliveryMode.entrypoint == 'door'"
            type="positive"
            text="Заберет курьер"
          />
          <CdekBadge
            v-if="deliveryMode.entrypoint == 'point' && deliveryMode.endpoint == 'door'"
            type="positive"
            text="Отгрузка в ПВЗ"
          />
        </div>
        <div class="service__price">{{ Number(price).toLocaleString() }} {{ currencySymbol }}</div>
      </div>
    </template>
  </RadioButton>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.tariff {
  margin: 1rem 0;
}
.service {
  display: flex;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  @include media-xs {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  &__title {
    flex-shrink: 0;
  }
  &__period {
    flex-shrink: 0;
    @include media-xs {
      flex-basis: 100%;
      order: 2;
      text-align: end;
    }
  }
  &__description {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-shrink: 0;
    @include media-xs {
      order: 3;
      margin-left: auto;
      .tooltip {
        display: none;
      }
    }
  }
  &__price {
    margin-left: auto;
    flex-shrink: 0;
    @include media-xs {
      order: 0;
    }
  }
}
</style>
