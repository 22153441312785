<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { CdekChip } from '@cdek-ui-kit/vue';

defineProps<{
  entrypoints: string[];
  modelValue: string | number | undefined;
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', mode: string): void;
}>();

const { t } = useI18n();
</script>

<template>
  <div class="calculator__option-buttons">
    <template v-for="(point, index) in entrypoints" :key="index">
      <CdekChip
          small
          :label="t(`selectTariff.tariffsList.entrypoints.${point}`)"
          @click="emits('update:modelValue', point)"
          :modelValue="point === modelValue"
      />
    </template>
  </div>
</template>

<style lang="scss">
@import '@cdek-ui-kit/vue/scss';

.calculator__option-buttons {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
  margin: 1.5rem 0 1rem 0;
  .calculator__option-button {
    border: none;
    outline: none;
    width: fit-content;
    border-radius: 100px;
    padding: 0.5rem 1rem;
    background-color: $Tertiary_5;
    cursor: pointer;
    &_active {
      background-color: rgb(var(--color));
      color: white;
    }
  }
}
</style>
