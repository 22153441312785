<template>
  <CdekInput
    v-model="fields.client.name"
    :label="t('sendRequest.name')"
    :valid-res="nameValidator.validated.value"
  />
</template>

<script setup lang="ts">
import { CdekInput } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
import { inject, toRef } from 'vue';
import ValidatorsBuilder from '@/services/Validator/ValidatorsBuilder';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';
const { t } = useI18n();

const container = useInjectContainer();
const fields = container.get(FieldsDTO);
const validatorsBuilder = inject('validatorsBuilder') as ValidatorsBuilder;

fields.client.name = null;

const nameRef = toRef(fields.client, 'name');

const nameValidator = validatorsBuilder.build(nameRef);
</script>
