import { inject } from 'mini-ioc';
import WidgetProps from '@/services/WidgetProps';

export default class GoogleRecaptcha {
  protected _captchaSiteKey: string | undefined;

  public constructor(protected _widgetProps = inject(WidgetProps)) {
    this._captchaSiteKey = this._widgetProps.captchaSiteKey;
  }

  public getToken = async () => {
    if (!this._captchaSiteKey) {
      throw new Error('Не указан ключ для капчи');
    }
    return await window.grecaptcha.execute(this._captchaSiteKey, { action: 'submit' });
  };
}
