<script setup lang="ts">
import Headline from './Headline.vue';
import OptionEntrypoints from '@/components/Tariffs/Standard/OptionEntrypoints.vue';
import useInjectContainer from '@/composables/useInjectContainer';
import TariffsService from '@/services/TariffsService';
import CalculateService from '@/services/Calculate/CalculateService';
import { computed, ref } from 'vue';
import CacheKeysService from '@/services/CacheKeysService';
import FieldsDTO from '@/services/FieldsDTO';
import Tariff from './Tariff.vue';
import OptionEndpoint from './OptionEndpoint.vue';
import WidgetProps from '@/services/WidgetProps';
import SelectTariffService from '@/services/SelectTariff/SelectTariffService';
import Route from './Route.vue';
import LinkBack from './LinkBack.vue';
import Line from './Line.vue';

defineProps<{
  payload: any;
}>();

const container = useInjectContainer();
const calculateService = container.get(CalculateService);
const tariffsService = container.get(TariffsService);
const cacheKeysService = container.get(CacheKeysService);
const fields = container.get(FieldsDTO);
const widgetProps = container.get(WidgetProps);
const selectTariffService = container.get(SelectTariffService);

tariffsService.tariffs = calculateService.tariffs?.serviceList ?? [];

const selectedEntrypoint = ref(tariffsService.entrypoints[0]);
const filteredTariffsByEntrypoint = computed(() =>
  tariffsService.filteredTariffsByEntrypoint(selectedEntrypoint.value),
);
const deliveryPeriod = (min: string | number, max: string | number) => {
  if (min === max) {
    return min;
  }
  return `${min} - ${max}`;
};

const selectServiceModeId = (id: number, tariff) => {
  cacheKeysService.nextUp();
  fields.modeId = id;
  selectTariffService.selectService(id, tariff);
};
</script>

<template>
  <Headline />
  <LinkBack />
  <Route />
  <Line />
  <OptionEntrypoints v-model="selectedEntrypoint" :entrypoints="tariffsService.entrypoints" />
  <OptionEndpoint :endpoints="filteredTariffsByEntrypoint.endpoints">
    <template #default="{ endpoint }">
      <div
        v-for="tariff in filteredTariffsByEntrypoint.filteredTariffs"
        :key="tariff.generalServiceId"
        class="option"
      >
        <template
          v-for="mode in tariff.modeDetails.filter(
            (mode) => mode.deliveryPoints.endpoint == endpoint,
          )"
        >
          <Tariff
            v-if="tariff.modeDetails"
            :key="mode.modeCode"
            :tariff-uuid="tariff.generalServiceId"
            :tariff-ec4-id="mode.tariffEc4Id"
            :service-name="tariff.serviceName"
            :price="mode.price"
            :delivery-period="deliveryPeriod(mode.calendarPeriodMin, mode.calendarPeriodMax)"
            :currency-symbol="widgetProps.currencySymbol"
            :selected-mode-id="fields.modeId"
            @select-service-mode-id="(id: number) => selectServiceModeId(id, tariff)"
          />
        </template>
      </div>
    </template>
  </OptionEndpoint>
</template>

<style lang="scss" scoped>
.option {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 1rem;
}
</style>
