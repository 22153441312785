import type WidgetPropsInterface from '@/interfaces/WidgetPropsInterface';
import ValidateAppInitParams from '@/services/AppInit/ValidateAppInitParams';
import I18nAppInit from '@/services/AppInit/I18nAppInit';
import { inject } from 'mini-ioc';

export default class AppInitService {
  public constructor(
    private _validator = inject(ValidateAppInitParams),
    private _localesBuilder = inject(I18nAppInit),
  ) {}

  public init(widgetProps: WidgetPropsInterface) {
    widgetProps = this._validator.validate(widgetProps) as WidgetPropsInterface;
    return {
      i18n: this._localesBuilder.createLocales(
        widgetProps?.language,
        widgetProps?.locales,
        widgetProps?.fallbackLanguage,
      ),
    };
  }
}
