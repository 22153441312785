<script setup lang="ts">
import { CdekHeadline } from '@cdek-ui-kit/vue';
import ElementBasis from '@/components/other/ElementBasis.vue';
import Counter from '@/components/other/Counter.vue';
import Segment from '@/components/other/Segment.vue';
import ParcelSize from './ParcelSize.vue';
import useInjectContainer from '@/composables/useInjectContainer';
import FieldsDTO from '@/services/FieldsDTO';

const container = useInjectContainer();
const fields = container.get(FieldsDTO);
fields.packages = {
  width: null,
  height: null,
  length: null,
  weight: null,
  parcelCount: '1',
};
fields.other = {
  packType: null,
  parcelCount: 1,
};

const packTypes = [
  {
    title: 'В коробке',
    value: 'box',
  },
  {
    title: 'На паллете',
    value: 'pallet',
  },
];
fields.other = {
  packType: packTypes[0].value,
};

const activeSegment = () => {
  if (fields.other.packType === 'pallet') {
    (fields.packages.length as unknown as string) = '180';
    (fields.packages.width as unknown as string) = '80';
    return;
  }
  (fields.packages.length as unknown as string) = '';
  (fields.packages.width as unknown as string) = '';
};
</script>

<template>
  <ElementBasis class="container">
    <div class="parcel-info">
      <div class="parcel-info__headline">
        <CdekHeadline size="7">Данные грузоместа</CdekHeadline>
      </div>
      <div class="parcel-info__segment">
        <Segment v-model="fields.other.packType" :items="packTypes" @click="activeSegment" />
      </div>
      <ParcelSize
        :packages="fields.packages"
        :readonly="{
          length: fields.other.packType === 'pallet',
          width: fields.other.packType === 'pallet',
        }"
      />
      <div class="parcel-info__line"></div>
      <div class="count">
        <span class="count__title">Кол-во грузомест</span>
        <div class="count__counter"><Counter v-model="fields.other.parcelCount" /></div>
      </div>
    </div>
  </ElementBasis>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.parcel-info {
  display: flex;
  flex-direction: column;
  &__headline {
  }
  &__size {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  &__flex-el {
    width: calc(25% - 1rem);
    flex-grow: 1;
    flex-shrink: 1;
    @include media-xs {
      width: calc(50% - 1rem);
    }
  }
  &__line {
    width: 100%;
    height: 1px;
    border: none;
    border-top: 1px dashed $Tertiary_20;
  }
}
.count {
  margin-top: 1rem;
  @include body-2;
  color: rgba(0, 0, 0, 0.6);

  display: flex;
  &__counter {
    margin-left: auto;
  }
}
.container {
  margin-bottom: 1rem;
}
</style>
