<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import WidgetProps from '@/services/WidgetProps';
import useInjectContainer from '@/composables/useInjectContainer';
import { CdekButton, CdekSpinner } from '@cdek-ui-kit/vue';
import GetService from '@/services/GetService/GetService';
import FieldsDTO from '@/services/FieldsDTO';
import WithAdditionServices from '@/decorators/GetService/WithAdditionServices';
const { t } = useI18n();

interface ITariffProps {
  tariff: any;
}
const props = defineProps<ITariffProps>();

const container = useInjectContainer();

const price = ref('');

const deliveryPeriod = ref('');

const getService = container.get(GetService) as WithAdditionServices;

const fields = container.get(FieldsDTO);

const widgetProps = container.get(WidgetProps);

const loading = ref(true);

getService
  .get(props.tariff, props.tariff.tariffEc4Id, [
    {
      alias: 'insurance',
      countOfService: fields.other.declaredCost,
    },
  ])
  .then((res) => {
    const { totalCost, deliveryDateInfo } = res;
    price.value = totalCost.total + ' ' + widgetProps.currencySymbol;
    deliveryPeriod.value = getDeliveryPeriod(deliveryDateInfo);
  })
  .finally(() => {
    loading.value = false;
  });

const getDeliveryPeriod = (deliveryDateInfo: any) => {
  const { calendarDeliveryDaysMin, calendarDeliveryDaysMax } = deliveryDateInfo;

  const text = calendarDeliveryDaysMax + ' ' + t('selectTariff.days');

  if (calendarDeliveryDaysMin === calendarDeliveryDaysMax) {
    return text;
  }

  return calendarDeliveryDaysMin + '-' + text;
};

const pointsText = computed(() => {
  const {
    deliveryPoints: { endpoint, entrypoint },
  } = props.tariff;

  const lowerCase =
    t(`selectTariff.tariffsList.entrypoints.${entrypoint}`) +
    ' ' +
    t(`selectTariff.tariffsList.endpoints.${endpoint}`).toLowerCase();

  const split = lowerCase.split('');

  const first = lowerCase[0].toUpperCase();

  split.splice(0, 1);

  return [first, ...split].join('');
});
</script>

<template>
  <div class="tariff">
    <div class="tariff__points">{{ pointsText }}</div>
    <div class="tariff__price">
      <cdek-spinner v-if="loading"></cdek-spinner>
      <div v-else>{{ price }}</div>
    </div>
    <div class="tariff__duration">{{ deliveryPeriod }}</div>
    <cdek-button as="a" theme="outline" href="https://lk.cdek.ru/user/login" small target="_blank">
      {{ t('selectTariff.createOrder') }}
    </cdek-button>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';

.tariff {
  border-radius: 8px;
  padding: 12px;
  background: $Bottom_5;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  @include media-min-lg {
    gap: 32px;
    grid-template-columns: 6fr 1fr 1fr 150px;
    background: $Peak;
    padding: 16px 0;
    border-top: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 0;

    &:last-child {
      border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
    }
  }

  &__points {
    @include headline-6;
    max-width: 220px;
    overflow-x: visible;
    white-space: nowrap;
    margin-bottom: 2px;

    @include media-min-lg {
      margin-bottom: 0;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: end;
    @include body-1;

    @include media-min-lg {
      grid-column: 3 / 4;
      justify-content: start;
    }
  }

  &__duration {
    min-height: 20px;
    grid-column: 2 span;
    @include body-2;

    @include media-min-lg {
      grid-column: 2 / 3;
      grid-row: 1 / 2;

      @include body-1;
    }
  }

  :deep(.green-button) {
    text-decoration: none;
    padding: 6px 12px;
    grid-column: 2 span;
    margin-top: 16px;

    @include media-min-lg {
      margin-top: 0;
      grid-column: 4 / 5;
    }
  }
}
</style>
