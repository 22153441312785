import type { GetServicesInterface } from '@/services/GetServices/GetServicesInterface';
import axios from 'axios';
import GoogleRecaptcha from '@/services/GoogleRecaptcha';
import type TariffsInterface from '@/interfaces/TariffsInterface';
import { inject } from 'mini-ioc';
import WidgetProps from '@/services/WidgetProps';
import FieldsDTO from '@/services/FieldsDTO';

export default class GetServicesService implements GetServicesInterface {
  public uri = '/api/v1/calculator/getServices';
  protected _tariffsServices: Set<string>;
  public captchaToken: string | undefined;

  public constructor(
    public widgetProps = inject(WidgetProps),
    public fields = inject(FieldsDTO),
    public captcha = inject(GoogleRecaptcha),
  ) {
    this._tariffsServices = new Set(this.widgetProps.tariffs.tariffs.map((tariff) => tariff.uuid));
    this.captcha = captcha;
  }

  public async get() {
    this.captchaToken = await this.captcha.getToken();
    const response = await axios.post(this.widgetProps.host + this.uri, this.getRequestBody(), {
      headers: {
        'X-User-Locale': this.widgetProps.language,
      },
    });
    if (response.status === 200 && response.data.data.serviceList.length) {
      return response.data.data;
    }
    throw new Error('bad request');
  }
  public getRequestBody() {
    return {
      sender: {
        cityId: this.fields.route.cityFrom?.uuid,
        contragentType: this.widgetProps.tariffs?.sender?.contragentType ?? 'FIZ',
      },
      receiver: {
        cityId: this.fields.route.cityTo?.uuid,
        contragentType: this.widgetProps.tariffs?.receiver?.contragentType ?? 'FIZ',
      },
      payer: {
        contractId: this.widgetProps.tariffs?.payer?.contractId ?? null,
        payerType: this.widgetProps.tariffs?.payer?.payerType ?? 'sender',
      },
      orderParam: {
        orderTypeCode: this.widgetProps.tariffs?.orderParam?.orderTypeCode ?? '1',
        additionalOrderTypeCodes:
          this.widgetProps.tariffs?.orderParam?.additionalOrderTypeCodes ?? [],
      },
      interfaceCode: this.widgetProps.tariffs?.interfaceCode ?? 'ec5_front',
      currencyMark: this.widgetProps.currencyMark,
      packages: [
        {
          length: Number(this.fields.packages.length),
          width: Number(this.fields.packages.width),
          height: Number(this.fields.packages.height),
          weight: Number(this.fields.packages.weight),
        },
      ],
      filters: {
        calcServicesId: [...this._tariffsServices],
      },
      token: this.captchaToken,
      language: this.widgetProps.language ?? 'eng',
    };
  }
}
