import AbstractComponentsResolver from '@/services/Components/AbstractComponentsResolver';
import { defineAsyncComponent } from 'vue';

export default class TariffsComponentResolver extends AbstractComponentsResolver {
  protected _tariffs() {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(() => import('@/components/Tariffs/Standard/Standard.vue')),
      },
      {
        key: 'G2M',
        component: defineAsyncComponent(
          () => import('@/components/Tariffs/G2M/G2MSelectTariff.vue'),
        ),
      },
      {
        key: 'LTL',
        component: defineAsyncComponent(() => import('@/components/Tariffs/LTL/LTL.vue')),
      },
    ];
  }

  protected _submitButton() {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/Templates/SelectTariff/ButtonSubmit.vue'),
        ),
      },
    ];
  }

  protected _components() {
    return {
      tariffs: this._tariffs(),
      submitButton: this._submitButton(),
    };
  }

  protected _defaults() {
    return {
      tariffs: {
        key: 'standard',
        props: {},
      },
      submitButton: {
        key: 'standard',
        props: {},
      },
    };
  }
}
