<script setup lang="ts">
import { CdekInput } from '@cdek-ui-kit/vue';
import { vMaska } from 'maska';
const props = defineProps<{
  modelValue: string | undefined;
}>();
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'change'): void;
}>();

const minus = () => {
  emit('update:modelValue', Math.max(1, (Number(props.modelValue) || 1) - 1).toString());
  emit('change');
};
const plus = () => {
  emit('update:modelValue', Math.min(999, (Number(props.modelValue) || 1) + 1).toString());
  emit('change');
};
</script>

<template>
  <div ref="test" class="green-counter counter">
    <button class="counter__button counter__button_minus" @click.stop.prevent="minus">
      <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.33594 1H10.6693"
          stroke="white"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div class="counter__input">
      <CdekInput
        v-maska
        small
        maxlength="4"
        :model-value="(modelValue as string) ?? '1'"
        data-maska="##"
        @update:model-value="(value: string) => emit('update:modelValue', Number(value) ? value : '1')"
        @change="() => emit('change')"
        @keydown.enter="() => emit('change')"
      >
      </CdekInput>
    </div>
    <button class="counter__button counter__button_plus" @click.stop.prevent="plus">
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.0026 1.33203V10.6654M1.33594 5.9987H10.6693"
          stroke="white"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<style lang="scss">
@import '@cdek-ui-kit/vue/scss';
.counter {
  display: flex;
  gap: 0.5rem;
  &__button {
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    &_minus {
      background-color: $Tertiary_70;
    }
    &_plus {
      background-color: $Primary_Button;
    }
  }
  &__input {
    height: 24px;
    .green-input {
      font-size: 0.5rem;
      width: 3rem;
      &__control {
        border-radius: 4px;
        padding-inline: 6px;
      }
      &__control_small {
        height: 24px !important;
      }
      &__input {
        font-size: 0.8rem;
        text-align: center;
      }
    }
  }
}
</style>
