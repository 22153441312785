import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "18",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M17.5 8A8.1 8.1 0 0 0 2 6m-.5-4v4h4m-4 4A8.1 8.1 0 0 0 17 12m.5 4v-4h-4" }, null, -1)
  ])))
}
export default { render: render }