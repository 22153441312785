import type WidgetPropsInterface from '@/interfaces/WidgetPropsInterface';

export default class ValidateAppInitParams {
  private _defaults = {
    host: '/',
    language: 'rus',
    fallbackLanguage: 'rus',
    currencyMark: 'RUB',
    currencySymbol: '₽',
    tariffs: [],
    locales: {},
  };
  public validate(
    widgetProps: WidgetPropsInterface,
  ): Record<string, Record<string, string | number | object>> {
    const result: Record<string, any> = {};
    const paramsForValidate = Object.keys(this._defaults);
    paramsForValidate.forEach((param) => {
      result[param] =
        widgetProps[param as keyof typeof widgetProps] ??
        this._defaults[param as keyof typeof this._defaults];
    });
    return result;
  }
}
