import AbstractComponentsResolver, {
  type ComponentResolverType,
  type ComponentType,
} from '@/services/Components/AbstractComponentsResolver';
import { defineAsyncComponent } from 'vue';

type Components = {
  fields: Fields;
};
type Fields = {
  name: ComponentType[];
  phone: ComponentType[];
  email: ComponentType[];
  senderType: ComponentType[];
  agree: ComponentType[];
  clientType: ComponentType[];
};

export default class SendRequestComponentResolver extends AbstractComponentsResolver {
  protected _components(): Components {
    return {
      fields: {
        clientType: this._fieldClientType(),
        name: this._fieldName(),
        phone: this._fieldPhone(),
        email: this._fieldEmail(),
        senderType: this._fieldSenderType(),
        agree: this._fieldAgree(),
      },
    };
  }

  protected _fieldName(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/SendRequest/Name/NameStandard.vue'),
        ),
      },
    ];
  }

  protected _fieldPhone(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/SendRequest/Phone/PhoneStandard.vue'),
        ),
      },
      {
        key: 'international',
        component: defineAsyncComponent(
            () => import('@/components/SendRequest/Phone/PhoneInternational.vue'),
        ),
      },
    ];
  }

  protected _fieldEmail(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/SendRequest/Email/EmailStandard.vue'),
        ),
      },
    ];
  }

  protected _fieldSenderType(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/SendRequest/SenderType/SenderStandard.vue'),
        ),
      },
    ];
  }

  protected _fieldAgree(): ComponentType[] {
    return [
      {
        key: 'standard',
        component: defineAsyncComponent(
          () => import('@/components/SendRequest/Agree/AgreeStandard.vue'),
        ),
      },
    ];
  }

  protected _fieldClientType(): ComponentType[] {
    return [
      {
        key: 'Velocity',
        component: defineAsyncComponent(
          () => import('@/components/SendRequest/ClientType/ClientTypeVelocity.vue'),
        ),
      },
    ];
  }

  protected _defaults() {
    return {
      fields: {
        name: {
          key: 'standard',
          props: {},
        },
        phone: {
          key: 'standard',
          props: {},
        },
        email: {
          key: 'standard',
          props: {},
        },
        senderType: {
          key: 'standard',
          props: {},
        },
        agree: {
          key: 'standard',
          props: {},
        },
      },
    };
  }
}
