<script setup lang="ts">
import { CdekButton } from '@cdek-ui-kit/vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
</script>

<template>
  <div class="submit">
    <CdekButton type="submit">{{ t('sendRequest.buttonSubmit') }}</CdekButton>
  </div>
</template>

<style lang="scss" scoped>
@import '@cdek-ui-kit/vue/scss';
.submit {
  width: 100%;
}
</style>
