import type Container from 'mini-ioc';
import BreadcrumbsComponentResolver from '@/services/Components/BreadcrumbsComponentResolver';
import TariffsComponentResolver from '@/services/Components/TariffsComponentResolver';
import WidgetProps from '@/services/WidgetProps';
import { type RealClass } from 'mini-ioc';
import StepControllerService from '@/services/StepControllerService';
import CityAutocompleteService from '@/services/CityAutocomplete/CityAutocompleteService';
import CityAutocompleteConfigurator from '@/services/CityAutocomplete/CityAutocompleteConfigurator';
import CalculateService from '@/services/Calculate/CalculateService';
import CalculateConfigurator from '@/services/Calculate/CalculateConfigurator';
import SendRequestComponentResolver from '@/services/Components/SendRequestComponentResolver';
import SendRequestService from '@/services/SendRequest/SendRequestService';
import SendRequestConfigurator from '@/services/SendRequest/SendRequestConfigurator';
import FinalComponentResolver from '@/services/Components/FinalComponentResolver';
import SelectTariffService from '@/services/SelectTariff/SelectTariffService';
import SelectTariffConfigurator from '@/services/SelectTariff/SelectTariffConfigurator';
import GetService from '@/services/GetService/GetService';
import GetServiceConfigurator from '@/services/GetService/GetServiceConfigurator';
import CalculateComponentsResolver from '@/services/Components/CalculateComponentsResolver';

export default class ServiceProvider {
  constructor(
    private _container: Container,
    private _componentsConfig: Record<string, any>,
    private _config: any,
  ) {}
  public boot() {
    this._componentsResolvers();
    this.singletons();
  }

  private _componentsResolvers() {
    this._container
      .get(BreadcrumbsComponentResolver)
      .setConfig(this._componentsConfig.breadcrumbs)
      .create();
    this._calculateComponents();
    this._selectTariffComponents();
    this._sendRequestComponents();
    this._finalComponents();
  }

  private _calculateComponents() {
    this._container
      .get(CalculateComponentsResolver)
      .setConfig(this._componentsConfig.calculate)
      .create();
  }
  private _selectTariffComponents() {
    this._container
      .get(TariffsComponentResolver)
      .setConfig(this._componentsConfig.selectTariff)
      .create();
  }
  private _sendRequestComponents() {
    this._container
      .get(SendRequestComponentResolver)
      .setConfig(this._componentsConfig?.sendRequest)
      .create();
  }
  private _finalComponents() {
    this._container.get(FinalComponentResolver).setConfig(this._componentsConfig.final).create();
  }

  private singletons() {
    this._container.registerResolver(WidgetProps, (classCtor: RealClass<any>) => {
      return new classCtor(
        this._config?.host,
        this._config?.components,
        this._config?.services,
        this._config?.color,
        this._config?.language,
        this._config?.currencyMark,
        this._config?.currencySymbol,
        this._config?.tariffs,
        this._config?.captchaSiteKey,
        this._config?.langs,
      );
    });

    this._container.registerResolver(StepControllerService, (classCtor: RealClass<any>) => {
      return new classCtor(Object.keys(this._config?.components));
    });
    this._container.registerResolver(CityAutocompleteService, (classCtor: RealClass<any>) => {
      const core = new classCtor();
      return new CityAutocompleteConfigurator(
        core,
        this._config?.services?.cityAutocomplete,
      ).configurate();
    });
    this._container.registerResolver(CalculateService, (classCtor: RealClass<any>) => {
      const core = new classCtor();
      return new CalculateConfigurator(core, this._config?.services?.calculate).configurate();
    });
    this._container.registerResolver(SelectTariffService, (classCtor: RealClass<any>) => {
      const core = new classCtor();
      return new SelectTariffConfigurator(core, this._config?.services?.selectTariff).configurate();
    });
    this._container.registerResolver(GetService, (classCtor: RealClass<any>) => {
      const core = new classCtor();
      return new GetServiceConfigurator(core, this._config?.services?.getService).configurate();
    });
    this._container.registerResolver(SendRequestService, (classCtor: RealClass<any>) => {
      const core = new classCtor();
      return new SendRequestConfigurator(core, this._config?.services?.sendRequest).configurate();
    });
  }
}
