import type TariffsInterface from '@/interfaces/TariffsInterface';

export default class WidgetProps {
  public constructor(
    public host: string,
    public components: Record<string, any>,
    public services: Record<string, any>,
    public color: string | undefined,
    public language: string,
    public currencyMark: string,
    public currencySymbol: string,
    public tariffs: TariffsInterface,
    public captchaSiteKey: string | undefined,
    public locales: Record<string, any>,
  ) {}
}
